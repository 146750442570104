<template>
  <div v-show="dataList.length > 0">
    <!-- 阴影 -->
    <div class="shadow"></div>
    <!-- 数据 -->
    <div class="approval-relativeList">
      <!-- 单个数据 -->
      <div v-for="(item, index) in dataList" :key="index" class="list_box">
        <!-- 名称 -->
        <div class="title">
          {{ item.objLabel }}
          <el-button
            size="mini"
            type="primary"
            v-if="
              PermissionById.isEdit &&
              item.permission &&
              item.objid !== 'AccountTeam20210326' &&
              item.objid !== '201603100108754gRole' &&
              item.objid !== '201603071422694Oppor' &&
              item.objid !== '2011000000062730EI25' &&
              item.objid !== 'track' &&
              item.objid !== '2013000001473776JtrA' &&
              item.objid !== 'attachement' &&
              item.objid !== '201609081008384marks'
            "
            @click="createdit(item)"
            >{{ $t("label.custom.new") }}</el-button
          >
        </div>
        <!-- 添加文件 -->
        <el-button
          size="small"
          class="add_work"
          v-if="item.objid === 'attachement' && isAddWork"
          @click="addWorkFun"
          >{{ $t("label.file.library.selectFile2Lib") }}</el-button
        >
        <!-- 数据 -->
        <!-- 数据 -->
        <el-table
          border
          :empty-text="$t('label.dashboard.noRecord')"
          :data="item.data"
        >
          <!-- @cell-click="goColDetail" -->
          <el-table-column
            height="30px"
            v-for="(Sitem, Sindex) in item.fieldList"
            :key="Sindex"
            :label="Sitem.label"
            :prop="
              Sitem.type == 'R' || Sitem.type == 'Y' || Sitem.type == 'M'
                ? Sitem.name + 'ccname'
                : Sitem.name
            "
            :class-name="
              isJump(Sitem.type, Sitem.name, item.data) ? 'relativeItem' : ''
            "
            @click="detailTurn(Sitem.type, Sitem.name, item.data)"
          >
            <!-- 实现右键新标标签页打开 -->
            <template slot-scope="scope">
              <span v-if="scope.column.className == '' && Sitem.type !== 'N'" v-html="scope.row[scope.column.property]"></span>
              <span v-else-if="scope.column.className == '' && Sitem.type === 'N'" >{{handleNum(scope.row[scope.column.property],Sitem)}}</span>
              <a
                v-show="scope.column.className != ''"
                :href="
                  scope.column.className != ''
                    ? scope.row.attach_type
                      ? `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${scope.row.id}`
                      : scope.column.property === 'name'
                      ? `#/commonObjects/detail/${scope.row.id}/DETAIL`
                      : scope.column.property.indexOf('ccname')
                      ? `#/commonObjects/detail/${
                          scope.row[
                            scope.column.property.slice(
                              0,
                              scope.column.property.length - 6
                            )
                          ]
                        }/DETAIL`
                      : `#/commonObjects/detail/${
                          scope.row[scope.column.property]
                        }/DETAIL`
                    : ''
                "
              >
                {{ scope.row[scope.column.property] }}
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 标准新建 -->
    <create-edit-obj
      ref="createEditObj"
      :objectApi="tabName"
      :relationFieldId="id"
      :prefix="prefix"
      :isTableEdit="true"
      :relativeRecordId="routerValue.objid"
      @update="getApoprovalList"
    >
    </create-edit-obj>
    <!-- 添加文件 -->
    <add-files
      ref="atoLib"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    ></add-files>
  </div>
</template>

<script>
import {
  queryApprovalRelatedList,
  Bind,
  getObjectPermission,
  getPermissionById,
} from "./api.js";
import { owneridIsJump } from "@/config/commonMehods/everyoneJump.js"; // 判断所有人是否跳转权限方法
import { AddFiles } from "@/components/index";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";

export default {
  components: {
    createEditObj,
    AddFiles,
  },
  data() {
    return {
      dataList: [],
      fieldValueList: {},
      baseURL: this.$baseConfig.baseURL,
      token: this.$CCDK.CCToken.getToken(),
      dialogAddVisible: false,
      isAddWork: false,
      PermissionById: {},
      Permission: "",
      tabName: "",
      id: "",
      prefix: "",
    };
  },
  props: ["routerValue"],
  mounted() {
    this.getApoprovalList();
    this.getPermissionById();
  },
  methods: {
    /**
     * 处理数字字段按照小数位展示
     * 暂时未考虑公式返回数字类型
     * @param {String} value:表格展示数据
     * @param {object} field:表格头展示数据
     */
     handleNum(value,field={}){
      if(value && field.decimalPlaces){
        let decimalPlaces = Number(field.decimalPlaces)
        value = Number(value).toFixed(decimalPlaces)
      }
      return value
    },
    createdit(item) {
      this.tabName = item.objName;
      this.id = item.fieldId;
      this.prefix = item.prefix;
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
    goColDetail(row, col) {
      if (col.className != "") {
        if (row.attach_type) {
          let baseURL = this.$baseConfig.baseURL;
          let token = this.$CCDK.CCToken.getToken();
          var link = document.createElement("a");
          link.setAttribute("download", "");
          link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${row.id}`;
          link.click();
        } else {
          if (col.property === "name") {
            this.$router.push({
              path: `/commonObjects/detail/${row.id}/DETAIL`,
            });
          } else {
            let property = "";
            if (col.property.indexOf("ccname")) {
              property = col.property.slice(0, col.property.length - 6);
            } else {
              property = col.property;
            }
            this.$router.push({
              path: `/commonObjects/detail/${row[property]}/DETAIL`,
            });
          }
        }
      }
    },
    // type类型为S Y M的跳转详情--详情信息的跳转
    detailTurn(type, name, item) {
      if (this.isJump(type, name, item)) {
        if (name === "name") {
          this.$router.push({
            path: `/commonObjects/detail/${this.fieldValueList.id}/DETAIL`,
          });
        } else {
          this.$router.push({
            path: `/commonObjects/detail/${this.fieldValueList[name]}/DETAIL`,
          });
        }
      }
    },
    // 判断字段是否可跳转
    isJump(fieldtype, fieldname, item) {
      if (
        ((fieldtype === "S" || fieldtype === "V") && fieldname === "name") ||
        (fieldtype === "Y" && fieldname !== "ownerid") ||
        (item.length > 0 &&
          fieldname === "ownerid" &&
          owneridIsJump(item[fieldname])) ||
        fieldtype === "M"
      ) {
        /* 可跳转：
            1.字段类型为S，且字段名称为name；
            2.字段类型为Y，但不包含所有人（所有人字段类型为Y）；
            3.字段名称为所有人，且符合所有人跳转规则；
            4.字段类型为M。
        */
        return true;
      } else {
        return false;
      }
    },
    // 获取审批相关列表
    async getApoprovalList() {
      this.dataList = [];
      let option = {
        relatedId: this.routerValue.objid,
        workItemId: this.routerValue.workItemid,
      };
      let result = await queryApprovalRelatedList(option);
      if (result.data.length > 0) {
        result.data.forEach((res) => {
          res = JSON.parse(res.relatedInfo);
          res.permission = "";
          if (res.objid) {
            //获取对象的权限
            getObjectPermission({ id: res.objid }).then((ress) => {
              if (ress.result) {
                res.permission = ress.data.add;
              }
            });
          }
          this.dataList.push(res);
          this.fieldValueList = Object.assign(this.fieldValueList, res.data[0]);
        });
      }
    },
    //判断添加文件按钮权限
    getPermissionById() {
      getPermissionById({ id: this.routerValue.objid }).then((res) => {
        this.PermissionById = res.data;
        if (res.data.isEdit) {
          this.isAddWork = true;
        } else {
          this.isAddWork = false;
        }
      });
    },
    //添加文件
    addWorkFun() {
      this.$refs.atoLib.initPage();
      this.dialogAddVisible = true;
    },
    //添加文件
    addWorkClose(n) {
      if (n) {
        this.getApoprovalList();
        this.dialogAddVisible = false;
      } else {
        this.dialogAddVisible = false;
      }
    },
    addWorkSubmit(checkedFileList) {
      let data = [];
      checkedFileList.map((item) => {
        let dataItem = {};
        dataItem.recordid = this.routerValue.objid;
        dataItem.name = item.name;
        dataItem.type = item.fileType ? item.fileType : item.type;
        dataItem.fileContentId = item.fileContentId || item.id; //下载文件2021开头的是filecontentid
        dataItem.fileinfoid = item.fileInfoId || item.id; // fil开头的是fileinfoid
        dataItem.filesize = item.filesize;
        data.push(dataItem);
      });
      Bind(JSON.stringify(data)).then((res) => {
        if (res.result === true) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"),
          });
        }
      });
      this.addWorkClose(true);
    },
  },
};
</script>

<style lang='scss' scoped>
a {
  color: #006dcc;
}
::v-deep .relativeItem {
  cursor: pointer;
  color: #0b5b9c;
}
.approval-relativeList {
  padding: 20px 60px;
}
.shadow {
  width: 100%;
  height: 38px;
  background: #f3f2f2;
  border-radius: 3px;
  border-top: 1px solid #dddbda;
}
.list_box {
  position: relative;
  .title {
    padding: 15px 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .add_work {
    border: 1px solid #dedcda;
    color: #006dcc;
    top: 10px;
    position: absolute;
    right: 0;
  }
  ::v-deep .add_work:hover,
  .add_work:focus {
    background: none;
  }
  .add_work {
    border: 1px solid #dedcda;
    color: #006dcc;
    top: 10px;
    position: absolute;
    right: 0;
  }
  ::v-deep .add_work:hover,
  .add_work:focus {
    background: none;
  }
  ::v-deep .el-table--border {
    font-size: 12px;
  }
  ::v-deep table .el-table thead {
    font-size: 12px !important;
    font-weight: 100 !important;
  }
  ::v-deep .el-table th {
    padding: 5px 0;
    color: #333;
    background-color: #fafaf9;
  }
  // 表头样式
  .el-table--border {
    border-top: 1px solid #dedcda;
    border-left: 1px solid #dedcda;
  }

  ::v-deep .el-table th.is-leaf {
    border-bottom: 1px solid #dedcda;
  }

  ::v-deep .el-table--border th {
    border-right: 1px solid #dedcda;
  }

  ::v-deep .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #dedcda;
  }
  ::v-deep .el-table td {
    padding: 5px 0;
    border-bottom: 1px solid #dedcda;
  }
  ::v-deep .el-table__empty-block {
    height: 35px !important;
    min-height: 0;
  }
  ::v-deep table {
    border-collapse: collapse !important;
  }
}
</style>
