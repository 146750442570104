<template>
  <!-- 表单 可编辑 -->
  <div class="base-form" :style="{ width: width }">
    <div class="text-center">
      <el-button @click="openInsertApproval" :loading="approvalLoading" v-if="btnInsert">
        <!-- 加签 -->
        {{ $t("label.insert.approval") }}
      </el-button>
      <el-button @click="submitForm('elForm')" :loading="approvalLoading">
        <!-- 批准 -->
        {{ $t("label.approve") }}
      </el-button>
      <el-button
        v-if="isShow"
        @click="refuse('elForm')"
        :loading="approvalLoading"
      >
        <!-- 拒绝 -->
        {{ $t("label.refuse") }}
      </el-button>
      <el-button @click="cancel" :loading="approvalLoading">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>
    <!-- {{isClientPoolEditStatus}} -->
    <el-form
      class="df"
      v-loading="formData.length"
      :status-icon="statusIcon"
      ref="elForm"
      :model="formData"
      :class="
        formAttrData && formAttrData.length === 1
          ? 'clearfix center-form'
          : 'clearfix'
      "
      :rules="rules"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
      :disabled="disabled"
    >
      <div
        class="pull-left"
        :style="{
          width:
            item.type === 'groupTitle' || item.type === 'customPage'
              ? '100%'
              : item.colNum === 1
              ? formAttrData && formAttrData.length === 1
                ? '100%'
                : oneColumnWidth
              : twoColumnWidth,
          clear: item.attrIndex % columnNum === 0 ? 'both' : '',
        }"
        v-for="item in formAttrData"
        :key="item.prop"
      >
        <template v-if="formData && !item.hidden && item.groupShow">
          <!-- <div class="only-body" v-if="!item.edit">
          <div class="only-can-readonly">
            <div class="title-only">{{ item.label }}</div>
            <div class="content">{{ item.fieldValue }}</div>
          </div>
        </div> -->
          <p
            class="groupTitle"
            v-if="item.type === 'groupTitle' && !item.hidden"
            v-html="item.title"
          ></p>
          <p
            v-else-if="item.type === 'noData' || item.hidden"
            style="height: 48px"
          ></p>

          <!-- 非文件只读字段 文字展示 -->
          <el-form-item
            v-if="item.type !== 'file' && !item.edit"
            :prop="item.prop"
            :class="[
              item.className,
              { 'no-label': item.noLabel, remind: item.remind },
            ]"
            :style="item.labelWidth ? 'min-height: 38px;' : 'min-height: 38px;'"
            :label-width="item.labelWidth ? item.labelWidth + 'px' : labelWidth"
          >
            <!-- 自定义表单label -->
            <span slot="label">
              <span class="span-box">
                <span>{{ item.label }}</span>
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  v-show="item.helpText"
                  placement="top-start"
                >
                  <div slot="content" v-html="helpText(item.helpText)"></div>
                  <svg class="icon helpImg" aria-hidden="true">
                    <use :href="'#icon-' + bangzhuImg"></use>
                  </svg>
                </el-tooltip>
              </span>
            </span>
            <!-- 只读字段 文字展示 -->
            <div
              class="content"
              v-if="item.type == 'remote-select' || item.name == 'name'"
            >
              <a class="linkcss" :href="linkDetail(item)">{{
                item.fieldValue
              }}</a>
            </div>
            <div class="content" v-else>{{ item.fieldValue }}</div>
          </el-form-item>

          <el-form-item
            v-else
            :prop="item.prop"
            :class="[
              item.className,
              { 'no-label': item.noLabel, remind: item.remind },
            ]"
            :style="item.labelWidth ? 'min-height: 38px;' : 'min-height: 38px;'"
            :label-width="item.labelWidth ? item.labelWidth + 'px' : labelWidth"
          >
            <!-- 自定义表单label -->
            <span slot="label">
              <span class="span-box">
                <span>{{ item.label }}</span>
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  v-show="item.helpText"
                  placement="top-start"
                >
                  <div slot="content" v-html="helpText(item.helpText)"></div>
                  <svg class="icon helpImg" aria-hidden="true">
                    <use :href="'#icon-' + bangzhuImg"></use>
                  </svg>
                </el-tooltip>
              </span>
            </span>
            <wang-editor
              v-if="item.type === 'kindeditor' && !item.query"
              :ref="item.refKey"
              :contents="formData[item.prop]"
              :disabled="!item.edit"
              @editorContent="
                onContentChange(item.changeEvent, arguments[0], item)
              "
            >
            </wang-editor>
            <!-- 审批意见 -->
            <el-input
              type="textarea"
              v-model="formData[item.prop]"
              v-if="item.type === 'advice'"
              :autosize="{
                minRows: item.minRows || 3,
                maxRows: item.maxRows || 6,
              }"
            ></el-input>
            <!-- 每个输入框 （文本类型） --------------------------------------------------------------------------------------------------------------------------------->
            <!-- :disabled="!item.edit" -->
            <el-input
              v-if="
                (item.type === 'input' ||
                  item.type === 'text' ||
                  item.type === 'password') &&
                !item.query &&
                !item.filterable &&
                !item.isAdress
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength ? item.maxlength : 1024"
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
              :class="
                userInfo.countryCode !== 'CN'
                  ? false
                  : item.apiname === 'name' &&
                    (specialObjectapi === 'Account' || objectApi === 'Account')
                  ? 'tianyancha'
                  : ''
              "
            >
            </el-input>
            <!-- 地理定位字段 -->
            <el-button
              class="icon big_map"
              type="primary"
              aria-hidden="true"
              v-if="item.type === 'input' && item.isAdress"
              @click="bigMapBtn(item)"
              :disabled="!item.edit"
            >
              <!-- 显示地图 -->
              {{ $t("c24") }}
            </el-button>
            <span
              v-if="
                userInfo.countryCode !== 'CN'
                  ? false
                  : item.apiname === 'name' &&
                    (specialObjectapi === 'Account' || objectApi === 'Account')
              "
              style="position: absolute"
            >
              <svg
                class="icon helpImg"
                aria-hidden="true"
                @click="tianyan"
                style="font-size: 75px; height: 21px; cursor: pointer"
              >
                <use :href="'#icon-' + img"></use>
              </svg>
            </span>
            <!-- 评分 -->
            <div :class="`stars ${item.apiname}`" v-if="item.type === 'score'">
              <span
                v-for="(star, idx) in Number(item.length) + 1"
                v-show="idx !== 0"
                :class="{ show2: idx <= Number(item.value) }"
                @mouseover="setStar(idx, item)"
                @mouseout="setStar('active', item)"
                @click="setClick(item, idx)"
                :key="idx"
              >
              </span>
            </div>
            <!-- 数字、百分比 -->
            <el-input-number
              v-if="
                (item.type === 'number' || item.type === 'percent-number') &&
                !item.query
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :controls="false"
              :placeholder="item.placeholder"
              :min="item.valueInterval.min"
              :max="item.valueInterval.max"
              :precision="item.precision || 0"
              controls-position="right"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input-number>
            <!--  动态检索项 -->
            <el-autocomplete
              class="el-input"
              v-if="item.query"
              v-model="formData[item.prop]"
              :fetch-suggestions="item.query"
              :placeholder="item.placeholder"
              @select="selecter(item.selecter, ...arguments)"
            >
            </el-autocomplete>
            <!-- ------------------------------------------搜索字段输入框---------------------------------------------- -->
            <!-- 查找/查找多选字段 -->
            <!-- :disabled="!item.edit" -->
            <!-- 项目管理系统-新建-实际工作清单菜单-问题/任务字段 -->
            <template
              v-if="isProjectSystem && item.apiname === 'taskOrProblem'"
            >
              <SearchRemoteTab
                :selectValue="item.value"
                :project_name="formData.project_name"
                :projectDataType.sync="formData.projectDataType"
                :taskOrProblem.sync="formData.taskOrProblem"
                :member="formData.member"
              />
            </template>
            <template v-else>
              <el-select
                v-if="
                  item.type === 'remote-select' ||
                  item.type === 'remote-multi-select'
                "
                v-model="formData[item.prop]"
                filterable
                :placeholder="$t('label.searchs')"
                :disabled="inputCheckIsDisabled(item)"
                :multiple="item.type === 'remote-multi-select' ? true : false"
                clearable
                remote
                :remote-method="(query) => remoteMethod(query, item)"
                :loading="loading"
                class="no-suffix"
                style="width: 100%"
                @blur="blurEvent"
                @change="changeEvent(item.changeEvent, arguments[0], item)"
              >
                <!-- @change="clearFilterOptions"> -->
                <p class="searchTipBox">
                  <span
                    @click="remoteSearch(item)"
                    style="display: inline-block; width: 100%"
                  >
                    <i class="el-icon-search"></i>
                    <span style="padding-left: 10px">
                      <!-- 前往精准搜索 -->
                      {{
                        $t("vue_label_commonobjects_detail_to_precise_search")
                      }}
                    </span>
                  </span>
                </p>
                <el-option
                  v-for="item in optionList[item.prop]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
                <el-option
                  v-show="false"
                  key="new"
                  :label="$t('label.new')"
                  value="新建"
                >
                </el-option>
              </el-select>
              <span
                v-if="
                  (item.type === 'remote-select' ||
                    item.type === 'remote-multi-select') &&
                  item.edit
                "
                class="remoteBtn"
                @click="remoteSearch(item)"
              >
                <!-- 公海池所属公海池在有值情况下，不能编辑，并且不显示搜索按钮 -->
                <i
                  class="el-icon-search"
                  v-if="!inputCheckIsDisabled(item)"
                ></i>
              </span>
            </template>
            <!--任务或事件类型的名称及相关项-->
            <!-- 名称 -->
            <div v-if="item.type === 'eventTypeA'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueA"
                slot="prepend"
                clearable
                :disabled="item.disabled"
                :placeholder="$t('label.select.please')"
                @change="eventChangeA"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.disabled === true ? 'dis' : ''"
                @click="item.disabled === true ? '' : checkItem(0, item)"
              >
                {{ eventCheckedObjA.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 相关项 -->
            <div v-if="item.type === 'eventTypeB'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueB"
                slot="prepend"
                clearable
                :disabled="item.disabled"
                :placeholder="$t('label.select.please')"
                @change="eventChangeB"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.disabled === true ? 'dis' : ''"
                @click="item.disabled === true ? '' : checkItem(1, item)"
              >
                {{ eventCheckedObjB.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 文件 -->
            <el-input
              v-if="item.type === 'file' && item.fieldType !== 'IMG'"
              v-show="false"
              v-model="formData[item.prop]"
            />
            <span
              v-if="
                item.type === 'file' &&
                item.fieldType !== 'IMG' &&
                item.dataFile
              "
            >
              <div
                class="deletetext"
                v-for="file in item.dataFile"
                :key="file.id"
              >
             <!-- 文件预览 -->
                <span
                  @click="openPreview(file)"
                  style="display: inline-block; cursor: pointer"
                  class="allow-click"
                >
                  {{ file.name }}.{{ file.fileType || file.type }}
                </span>
                <svg
                  v-if="file"
                  class="icon"
                  aria-hidden="true"
                  style="width: 11px"
                  @click="deletext(file, item)"
                >
                  <use href="#icon-close"></use>
                </svg>
              </div>
            </span>
            <el-button
              v-if="item.type === 'file' && item.fieldType !== 'IMG'"
              :disabled="!item.edit"
              type="primary"
              size="mini"
              @click="addFile(item)"
            >
              <!-- 选择文件 -->
              {{ $t("label.selectfile") }}
            </el-button>
            <!-- 图片引用 -->
            <el-input
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType === 'url'
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength ? item.maxlength : 1024"
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input>
            <!-- 图片 -->

            <!-- 新版图片预览上传显示 -->
            <!-- item.readonly === '1' {{item.readonly === '1'}} -》{{item}} -->
            <AddPicModule
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType !== 'url'
              "
              :item="itemFormat(item)"
              :formData="formData"
              @uploadFileSuccess="uploadFileSuccess"
              :readonly="item.readonly === '1'"
              :isLightningOriginal="item.expressionType == 'lightning-ref'"
            />

            <!-- 长文本 -->
            <el-input
              v-if="item.type === 'textarea'"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength ? item.maxlength : 1024"
              :autosize="{
                minRows: item.minRows || 3,
                maxRows: item.maxRows || 6,
              }"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-input>
            <el-radio-group
              v-model="formData[item.prop]"
              v-if="item.type === 'radio'"
              :disabled="!item.edit"
            >
              <span
                v-for="(child, index) in item.options"
                class="plr-10"
                :key="index"
              >
                <el-radio :label="child.val">{{ child.key }}</el-radio>
              </span>
            </el-radio-group>
            <!-- 多选框  用checkedLabel和 checkedKey来区分取值 -->
            <el-checkbox-group
              v-model="formData[item.prop]"
              v-if="item.type === 'checked'"
              :disabled="!item.edit"
            >
              <template v-for="(checkedItem, index) in item.checkedList">
                <el-checkbox
                  :label="checkedItem[item.checkedKey]"
                  :name="item.prop"
                  @change="changeEvent(item.changeEvent, arguments[0], item)"
                  :key="index"
                >
                  {{ checkedItem[item.checkedLabel] }}
                </el-checkbox>
              </template>
            </el-checkbox-group>
            <!-- 下拉框 -->
            <el-select
              v-if="item.type === 'select' || item.type === 'multi-select'"
              v-model="formData[item.prop]"
              :disabled="!item.edit"
              clearable
              :multiple="item.type === 'multi-select' ? true : false"
              :filterable="filterable"
              :placeholder="item.placeholder"
              @visible-change="visibleChange"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="types == 'progress' ? selectBlur($event, item) : ''"
              style="width: 100%"
            >
              <template v-for="opt in optionList[item.prop]">
                <el-option :key="opt.key" :label="opt.key" :value="opt.val">
                </el-option>
              </template>
            </el-select>
            <!-- 复选框 -->
            <el-checkbox
              v-if="item.type === 'checkbox'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
              <!-- {{item.label}} -->
            </el-checkbox>
            <!-- 级联选择器 -->
            <el-cascader
              v-if="item.type === 'cascader'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              :options="item.options"
              style="width: 100%"
              filterable
              :show-all-levels="item.showAllLevels || false"
            >
            </el-cascader>
            <!-- 日期 -->
            <el-date-picker
              v-if="item.type === 'date'"
              :disabled="!item.edit"
              :editable="false"
              v-model="formData[item.prop]"
              type="date"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd"
              :format="dateFormat"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 日期时间 -->
            <el-date-picker
              v-if="item.type === 'datetime'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="datetimeFormat"
              v-model="formData[item.prop]"
              type="datetime"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 时间 -->
            <el-time-select
              v-if="item.type === 'time'"
              :disabled="!item.edit"
              value-format="HH:mm:ss"
              :format="timeFormat"
              v-model="formData[item.prop]"
              :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-time-select>
            <!-- 年份 -->
            <el-date-picker
              v-if="item.type === 'dateYear'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy"
              v-model="formData[item.prop]"
              type="year"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 提醒 -->
            <span class="remind-text" v-if="item.remind">
              {{ item.remind }}
            </span>
          </el-form-item>
        </template>
      </div>
    </el-form>

    <div class="text-center" style="margin-bottom: 32px">
      <el-button
        @click="submitForm('elForm')"
        class="left"
        :loading="approvalLoading"
      >
        <!-- 批准 -->
        {{ $t("label.approve") }}
      </el-button>
      <el-button
        v-if="isShow"
        @click="refuse('elForm')"
        :loading="approvalLoading"
      >
        <!-- 拒绝 -->
        {{ $t("label.refuse") }}
      </el-button>
      <el-button @click="cancel" :loading="approvalLoading">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :isShowClearBtn="!isInsert"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          :optionCheckedArr="optionCheckedArr"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>
    <div class="return-approval-step">
      <el-dialog
        :title="$t('label.approval.select.step')"
        :visible.sync="isSelect"
        width="550px"
      >
        <return-approval-step
          @changeShow="changeShow"
          @submitStep="submitStep"
          @changeSearch="changeSearch"
          :relatedId="edictValue.objid"
          :stepTabList="stepTabList"
        ></return-approval-step>
      </el-dialog>
    </div>
    <tian-yan-cha ref="tian"></tian-yan-cha>
    <el-dialog
      title=""
      :width="mapWidth"
      :top="mapTop"
      :visible.sync="bigMapDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      custom-class="big-view-box"
    >
      <el-button-group class="btn_map" v-if="mapTop === '6%'">
        <!-- 高德地图 谷歌地图-->
        <!-- 隐藏切换按钮 -->
        <!-- <el-button
          :style="{
            background: isMap ? '#006DCC' : '',
            color: isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gd')"
          >{{ $t("label.gaode.map") }}</el-button
        >
        <el-button
          :style="{
            background: !isMap ? '#006DCC' : '',
            color: !isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gg')"
          >{{ $t("label.google.map") }}</el-button
        > -->
      </el-button-group>
      <!-- 高德/谷歌地图 -->
      <map-group
        ref="mapGroup"
        :mapkey="mapkey"
        :pointValue="clearItemArr.gdPointValue"
        :ggPointValue="clearItemArr.ggPointValue"
        :lineIndex="lineIndex"
        :isInfoMap="isInfoMap"
        :isMap="isMap"
        @chooseLocation="chooseLocation"
        @clearLocation="clearLocation"
        @bigClose="bigClose"
      ></map-group>
    </el-dialog>

    <!-- 加签弹窗 -->
    <el-dialog :title="$t('label.insert.approval')" :visible.sync="dialogApproval" :before-close="beforeCloseInsert">
      <div class="insert-box" v-if="dialogApproval">
        <el-form
          :model="formDataInsert"
          :rules="rulesInsert"
          ref="ruleForm"
          label-position="top"
          class="demo-ruleForm"
        >
          <!-- 批准人 -->
          <el-form-item :label="$t('label.approver')" prop="people">
            <div class="checked-approvaler">
              <div v-for="item in formDataInsert.people"
                class="approvaler"
                :key="item">
                <div class="header">
                  {{ selectApprovalers[item].charAt(0) }}
                </div>
                <div class="name">
                  {{ selectApprovalers[item] }}
                </div>
                <i class="del-icon el-icon-error" @click="delApprovaler(item)"></i>
              </div>
              <div class="approvaler-option">
                <div class="approvaler-add" @click="remoteSearchInsert">
                  +
                </div>
                <div class="name">
                  <!-- 请添加 -->
                  {{ $t("c2433") }} 
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            :label="$t('c2434')"
            :required="formDataInsert.people.length >= 2"
            disabled
          >
            <el-select 
              v-if="formDataInsert.people.length >= 2"
              v-model="formDataInsert.ApprovalType">
              <el-option
                :label="$t('c2158')"
                value="apprroutingapprallrouting"
              ></el-option>
              <el-option
                :label="$t('c2159')"
                value="apprroutingapprfirstrouting"
              ></el-option>
            </el-select>
            <el-select 
              v-if="formDataInsert.people.length < 2"
              placeholder=""
              v-model="blankVal"
              disabled>
            </el-select>
          </el-form-item>
          <!-- 加签理由 -->
          <el-form-item :label="$t('label.description')" prop="remarks">
            <el-input
              type="textarea"
              :placeholder="$t('c2435')"
              v-model="formDataInsert.remarks"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="beforeCloseInsert"
          :loading="approvalLoading"
        >
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="approvalDefineClick('ruleForm')"
          :loading="approvalLoading"
          style="background: #006DCC;color: #fff"
        >
          {{ $t("label_chatter_ok") }}
        </el-button>
      </span>
    </el-dialog>


 <!-- 文件预览 -->
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * 审批表单
 * 功能：
 * 1、根据传入的表单字段信息展示不同的字段样式
 * 2、对查找字段进行查找
 * 3、高德和谷歌地图引用并回填数据
 */
import { IEVersion } from "@/utils/brower";
import * as Time from "@/utils/time";
import * as CommonObjApi from "./api";
import WangEditor from "@/components/wangEditor/index.vue";
import Bus from "@/mixin/bus.js";
import tianYanCha from "@/components/Tianyancha";
import SearchRemoteTab from "@/components/Form/SearchRemoteTab";
import returnApprovalStep from "@/views/approval/components/return-approval-step.vue";
import { 
  edictApproval,
  rejectEdictApproval,
  approvalSave,
  getApprovalPre,
 } from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
import debounce from "lodash.debounce";
import MapGroup from "@/components/Map/index.vue";
import SearchTable from "@/components/Form/search-table";
import filePreview from "@/components/FileView/filePreview";

export default {
  name: "base-form",
  props: {
    // 记录id
    historyID: { type: String },
    routerValue: {
      type: Object,
    },
    // 是否显示拒绝按钮
    isShow: {
      type: Boolean,
      default: true,
    },
    // 表单中所有select选择器选项集合
    optionList: {
      type: Object,
      default: () => {},
    },
    // 表单label宽度
    labelWidth: {
      type: [Number, String],
      default: "150px",
    },
    // 表单label所在位置
    labelPosition: {
      type: String,
      default: "left",
    },
    // 几列布局
    columnNum: {
      type: Number,
      default: 2,
    },
    // 一列占宽百分比
    oneColumnWidth: {
      type: String,
      default: "100%",
    },
    // 两列占宽百分比
    twoColumnWidth: {
      type: String,
      default: "50%",
    },
    // 整个表单宽度
    width: {
      type: String,
    },
    // 是否显示验证
    statusIcon: {
      type: Boolean,
      default: true,
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 表单数据对象
    formAttr: {
      type: Array,
      default: () => [],
    },
    // 表单数据记录所属对象api,用于判断是否为事件类型，动态更改时间
    specialObjectapi: {
      type: String,
    },
    // 鼠标移出输入框绑定事件
    handleBlur: {
      // 处理移出事件
      type: [Function, undefined],
      default: undefined,
    },
    // 值发生变化时是否自动刷新表单
    autoRefresh: {
      type: Boolean,
      default: true,
    },
    // 是否禁用该表单内的所有组件。若设置为 true，则表单内组件上的 disabled 属性不再生效
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否详情页
    detail: {
      type: Boolean,
      default: false,
    },
    // 数据id
    id: {
      type: String,
    },
    //对象API
    objectApi: {
      type: String,
    },
    //判断是否是启用外部联系人
    contactes: {
      type: String,
    },
    // 公海池编辑状态
    isClientPoolEditStatus: {
      type: Boolean,
    },
    // 使用位置是否在进度条下
    types: {
      type: String,
    },
  },
  components: {
    WangEditor,
    tianYanCha,
    SearchRemoteTab,
    returnApprovalStep,
    MapGroup,
    AddPicModule: () => import("@/components/Form/components/AddPicModule.vue"),
    SearchTable,
    filePreview
  },
  data() {
    return {
      showPreview: false, // 预览
      showPreviewData: {}, // 预览数据
      //选择返回的审批步骤
      isSelect: false,
      //审批拒绝后返回第几步
      selectStepId: "",
      //详情页传过来的群ID
      detailGroupId: "",
      //批准loading
      approvalLoading: false,
      //判断审批意见是否必填
      adviceProp: "",
      //返回审批步骤
      stepTabList: [],
      //编辑审批参数
      edictValue: this.routerValue || {},
      //提交的类型
      submitType: "",
      //对象名称
      objectApiName: "",
      //审批意见
      approvalAdvice: "",
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      possibilityList: [],
      // 相关项及名称
      defaultPrefix: {},
      // 任务或者事件对象名称字段显示数据
      eventCheckedObjA: {},
      // 任务或者事件对象相关项字段显示数据
      eventCheckedObjB: {},
      // 区分名称和相关项赋值
      eventIndex: -1,
      // 任务或者事件对象名称字段相关对象
      eventValueA: "",
      // 任务或者事件对象相关项字段相关对象
      eventValueB: "",
      // 时间差
      timeDiff: 0,
      // 日期类型字段格式化
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      // 日期时间类型字段格式化
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      // 时间类型字段格式化
      timeFormat: Time.timeFormat("USA"),
      // 字段邮件校验
      isEmail: (rule, value, callback) => {
        const reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !reg.test(value)) {
          // '请输入正确的电子邮箱地址'
          return callback(
            new Error(this.$i18n.t("label_emailtocloudcc_redmessage1"))
          );
        } else {
          callback();
        }
      },
      // 字段为数值校验
      isNumber: (rule, value, callback) => {
        if (value !== undefined && isNaN(value)) {
          // 您输入的不是有效数字
          return callback(new Error(this.$i18n.t("label.field.input.number")));
        } else {
          callback();
        }
      },
      // 查找字段相关对象id
      relevantObjId: "",
      // 查找字段相关对象api
      relevantObjApi: "",
      // 查找字段相关对象前缀
      relevantPrefix: "",
      // 是否展示查找/查找多选弹窗
      showSearchTable: false,
      //文件id
      fieldId: "",
      // 查找/查找多选字段弹窗中的表格是否允许多选,字段类型为查找多选时才可多选
      checked: false,
      // 当前表格数据
      formData: {},
      // 验证规则
      rules: {},
      // 是否可检索
      filterable: true,
      // 远程搜索loading
      loading: false,
      // 远程搜索用于显示的数组
      filterOptions: [],
      // 文件类型字段上传文件大小(M)
      fileSize: 1024,
      // 查找/查找多选弹窗主体高度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      // 评分类型字段元素集合
      stars: null,
      //帮助图标
      bangzhuImg: "bangzhu-changtai",
      //天眼查图标
      img: "icon-tianyancha",
      // 当前登录用户信息
      userInfo: {},
      //是否为项目管理系统
      isProjectSystem: false,
      // 地图对象
      mapObject: null,
      // 是否展示谷歌/高德地图弹窗
      bigMapDialog: false,
      // 地址类型字段信息
      clearItemArr: {},
      // 谷歌/高德地图密钥
      mapkey: "",
      // 谷歌/高德动态地图id
      lineIndex: Math.ceil(Math.random() * 100),
      //地图弹框宽度
      mapWidth: "80%",
      //地图顶部高度
      mapTop: "6%",
      //判断是否为地图字段
      isMap: true,
      //是否是详情页
      isInfoMap: false,

      // 加签弹窗
      dialogApproval: false,
      // 加签数据
      formDataInsert: {
        people: [],
        remarks: "",
        ApprovalType: "apprroutingapprallrouting",
      },
      // 选中的审批人
      selectApprovalers: {},
      // 加签按钮是否展示
      btnInsert: false,
      // 加签检验
      rulesInsert: {
        // 值不能为空
        people: [
          {
            required: true,
            message: this.$i18n.t("label.value.notnull"),
            trigger: "change",
          },
        ],
        remarks: [
          {
            required: false,
            message: this.$i18n.t("label.value.notnull"),
            trigger: "change",
          },
        ],
      },
      // 是否加签
      isInsert: false,
      // 已选择审批人
      optionCheckedArr: [],
      // 空白值
      blankVal: ""
    };
  },
  created() {
    this.queryLimit();
    this.filterFormData();
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
    this.listenStorage();
    this.getUserInfo();
    //项目管理系统
    if (this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q") {
      this.isProjectSystem = true;
    }
    this.getApprovalPreview();
  },
  mounted() {
    this.$Bus.$on("deliver-edict-approval", this.ebFn1);
    this.$Bus.$on("deliver-objectApiName", this.ebFn2);
    this.$Bus.$on("deliver-advice-prop", this.ebFn3);
    Bus.$on("windowResize", this.ebFn4);
    this.getApprovalPreview()
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-edict-approval", this.ebFn1);
    this.$Bus.$off("deliver-objectApiName", this.ebFn2);
    this.$Bus.$off("deliver-advice-prop", this.ebFn3);
    Bus.$off("windowResize", this.ebFn4);
    window.removeEventListener("storage", this.listenStorageFn);
  },
  methods: {
    // 关闭预览
     closePreview() {
      // 列表打开预览
      this.showPreview = false;
      this.previewUrl = "";
    },
    // 上传完成，更新模板
    uploadFileSuccess({ data, prop }) {
      // 图片字段字符串
      let idStr = data.map(item=>item.id).join(";");
      this.$set(this.formData, prop, idStr);
    },
    /*
     * 文件上传成功
     */
    // uploadFileSuccess(prop, fileId, isPng) {
    //   if (this.formData[prop] === undefined || this.formData[prop] === "") {
    //     this.$set(this.formData, prop, fileId);
    //   } else {
    //     if (isPng) {
    //       this.$set(this.formData, prop, fileId);
    //     } else {
    //       this.$set(this.formData, prop, `${this.formData[prop]}${fileId}`);
    //     }
    //   }
    // },
    ebFn1(res) {
      this.edictValue = res;
    },
    ebFn2(res) {
      this.objectApiName = res;
    },
    ebFn3(res) {
      this.adviceProp = res == "1" ? "advice" : "";
    },
    ebFn4(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    /*
     * 查找字段name字段跳转详情页
     */
    linkDetail(item) {
      if (item.type == "remote-select" || item.name == "name") {
        return item.detailPageID
          ? `#/commonObjects/detail/${item.detailPageID}/DETAIL`
          : "";
      } else {
        return `javascript:void(0);`;
      }
    },
    /*
     * 地图类型更改
     */
    mapBtnChange(e) {
      if (e === "gd") {
        this.isMap = true;
        this.mapFun();
      } else {
        this.isMap = false;
        this.mapFun();
      }
    },
    /*
     * 显示地图
     */
    bigMapBtn(item) {
      this.clearItemArr = item;
      this.bigMapDialog = true;
      if (this.formData[this.clearItemArr.prop + "00"]) {
        if (
          this.formData[this.clearItemArr.prop + "00"] ===
            this.$i18n.t("label.china") ||
          this.formData[this.clearItemArr.prop + "00"] ===
            this.$i18n.t("label.the.peoples.republic.of.china") ||
          this.formData[this.clearItemArr.prop + "00"] === "China"
        ) {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      } else {
        if (this.userInfo.countryCode === "CN") {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      }
    },
    /*
     * 高德地图初始化
     */
    mapFun() {
      if (this.isMap) {
        if (
          ((this.formData[this.clearItemArr.prop + "00"] &&
            this.formData[this.clearItemArr.prop + "00"] ===
              this.$i18n.t("label.china")) ||
            this.formData[this.clearItemArr.prop + "00"] ===
              this.$i18n.t("label.the.peoples.republic.of.china") ||
            this.formData[this.clearItemArr.prop + "00"] === "China") &&
          this.formData[this.clearItemArr.prop + "longitude"] &&
          this.formData[this.clearItemArr.prop + "latitude"]
        ) {
          this.clearItemArr.gdPointValue = [
            this.formData[this.clearItemArr.prop + "longitude"],
            this.formData[this.clearItemArr.prop + "latitude"],
          ];
        } else {
          this.clearItemArr.gdPointValue = [];
        }
        this.mapkey = window.Glod['AMAP_KEY']
      } else {
        if (
          this.formData[this.clearItemArr.prop + "00"] &&
          this.formData[this.clearItemArr.prop + "00"] !==
            this.$i18n.t("label.china") &&
          this.formData[this.clearItemArr.prop + "00"] !==
            this.$i18n.t("label.the.peoples.republic.of.china") &&
          this.formData[this.clearItemArr.prop + "00"] !== "China" &&
          this.formData[this.clearItemArr.prop + "longitude"] &&
          this.formData[this.clearItemArr.prop + "latitude"]
        ) {
          this.clearItemArr.ggPointValue = {
            lat: Number(this.formData[this.clearItemArr.prop + "longitude"]),
            lng: Number(this.formData[this.clearItemArr.prop + "latitude"]),
          };
        } else {
          this.clearItemArr.ggPointValue = {};
        }
        this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      }
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
        this.$refs.mapGroup.searchKey = "";
        this.$refs.mapGroup.searchList = [];
      });
    },
    /*
     * 关闭地图
     */
    bigClose() {
      this.bigMapDialog = false;
    },
    /*
     * 地图返回信息
     */
    chooseLocation(allAdd, obj) {
      this.bigMapDialog = false;
      //CN 高德地图 ，海外谷歌
      if (this.isMap) {
        if (allAdd.addressComponent.country !== undefined) {
          this.formAttrData.forEach((val) => {
            if (val.prop === this.clearItemArr.prop + "00") {
              this.$emit(
                "control",
                allAdd.addressComponent.country,
                val.fieldId,
                this.formData
              );
            }
          });
        }
        this.clearItemArr.gdPointValue = obj;
        this.formData[this.clearItemArr.prop + "00"] =
          allAdd.addressComponent.country;
        this.formData[this.clearItemArr.prop + "01"] =
          allAdd.addressComponent.province;
        this.formData[this.clearItemArr.prop + "02"] =
          allAdd.addressComponent.city;
        this.formData[this.clearItemArr.prop + "04"] = allAdd.formattedAddress;
        this.formData[this.clearItemArr.prop + "longitude"] = obj[0];
        this.formData[this.clearItemArr.prop + "latitude"] = obj[1];
      } else {
        this.clearItemArr.ggPointValue = allAdd.geometry.location;
        let allAddLength = "";
        allAdd.address_components.forEach((val, index) => {
          //如果是美国/加拿大/澳大利亚/墨西哥/英国/新加坡/法国/德国/的话找对应下标赋值
          if (
            val.short_name === "US" ||
            val.short_name === "CA" ||
            val.short_name === "AU" ||
            val.short_name === "MX" ||
            val.short_name === "GB" ||
            val.short_name === "SG" ||
            val.short_name === "FR" ||
            val.short_name === "DE"
          ) {
            allAddLength = index;
          }
        });
        this.formAttrData.forEach((val) => {
          if (val.prop === this.clearItemArr.prop + "00") {
            this.$emit(
              "control",
              allAdd.address_components[allAddLength].long_name,
              val.fieldId,
              this.formData
            );
          }
        });
        this.formData[this.clearItemArr.prop + "00"] =
          allAdd.address_components[allAddLength].long_name;
        this.formData[this.clearItemArr.prop + "01"] =
          allAdd.address_components[allAddLength - 1].long_name;
        this.formData[this.clearItemArr.prop + "02"] =
          allAdd.address_components[allAddLength - 2].long_name;
        this.formData[this.clearItemArr.prop + "04"] = allAdd.formatted_address;
        this.formData[this.clearItemArr.prop + "longitude"] =
          allAdd.geometry.location.lat;
        this.formData[this.clearItemArr.prop + "latitude"] =
          allAdd.geometry.location.lng;
      }
    },
    /*
     * 清空地图信息
     */
    clearLocation() {
      this.bigMapDialog = false;
      this.clearItemArr.gdPointValue = [];
      this.clearItemArr.ggPointValue = {};
      this.formData[this.clearItemArr.prop + "00"] = "";
      this.formData[this.clearItemArr.prop + "01"] = "";
      this.formData[this.clearItemArr.prop + "02"] = "";
      this.formData[this.clearItemArr.prop + "04"] = "";
    },
    /*
     * 删除审批文件
     */
    deletext(val, item) {
      if (item.edit == true) {
        this.formAttrData.forEach((res) => {
          if (res.apiname == item.apiname) {
            let arr = res.dataFile;
            arr.forEach((va, ind) => {
              if (va.id == val.id) {
                arr.splice(ind, 1);
              }
            });
          }
        });
        let arry = this.formData[item.apiname].split(",");
        arry.forEach((res, index) => {
          if (res == val.id) {
            arry.splice(index, 1);
          }
        });
        this.formData[item.apiname] = arry.join(",");
      } else {
        this.$message.warning(this.$i18n.t("label.commonobjects.Nopermission"));
      }
    },
    /*
     * 处理帮助文本特殊字符回车显示#enter类似问题
     */
    helpText(helpText) {
      if (helpText === undefined || helpText === null) {
        return helpText;
      } else {
        return helpText
          .replace(/#enter/g, "<br />")
          .replace(/#quoteDouble/g, '"')
          .replace(/#quoteSingle/g, "'")
          .replace(/#quote/g, "'");
      }
    },
    /*
     * 获取审批步骤
     */
    submitStep() {
      this.getApprovalList();
    },
    /*
     * 获取返回步骤的ID
     */
    changeSearch(id) {
      this.selectStepId = id;
    },
    /*
     * 审批步骤点击取消
     */
    changeShow() {
      this.isSelect = false;
    },
    /*
     * 拒绝请求
     */
    rejectApproval: debounce(
      async function () {
        let option = {
          relatedId: this.edictValue.objid,
          workItemId: this.edictValue.workItemid,
        };
        let result = await rejectEdictApproval(option);
        if (result.result == true && result.data.isShowRejectedStep == false) {
          this.getApprovalList();
        } else if (
          result.result == true &&
          result.data.isShowRejectedStep == true
        ) {
          this.approvalLoading = false;
          this.stepTabList = result.data.rejectedStepList;
          // this.$Bus.$emit('deliver-step-list-arr',result.data.rejectedStepList);
          this.isSelect = true;
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
    /*
     * 编辑审批
     */
    async getApprovalList() {
      for (const key in this.formData) {
        if (this.formData[key] instanceof Array) {
          this.formData[key] = this.formData[key].join(";");
        }
        if (typeof this.formData[key] == "number") {
          this.formAttrData.forEach((res) => {
            if (
              res.apiname == key &&
              res.apiname.slice(-9) !== "longitude" &&
              res.apiname.slice(-8) !== "latitude"
            ) {
              this.formData[key] = this.formData[key].toFixed(res.precision);
            }
          });
        }
        if (typeof this.formData[key] == "boolean") {
          this.formData[key] = this.formData[key].toString();
        }
      }
      let dataArr = JSON.parse(JSON.stringify(this.formData));
      let advice = this.formData.advice;
      delete dataArr.advice;
      let readonly = this.formAttrData;
      let readList = [];
      readonly.forEach((item) => {
        if (item.readonly === "1" && item.name !== "cardphoto") {
          readList.push(item);
        }
      });
      readList.forEach((item) => {
        delete dataArr[item.name];
      });
      delete dataArr.undefined;
      dataArr.id = this.edictValue.objid;
      dataArr = JSON.stringify([dataArr]);
      let option = {
        data: dataArr,
        relatedId: this.edictValue.objid,
        comments: advice,
        workItemId: this.edictValue.workItemid,
        actionType: this.submitType,
        objectApiName: this.objectApiName,
        stepId: this.selectStepId,
      };
      try {
        let result = await edictApproval(option);
        if (result.returnInfo == "Success!") {
          this.approvalLoading = false;
          if (this.$route.query.isHistory === "2") {
            this.$router.go(-1);
            let detail = "";
            if (this.submitType == "Approved") {
              // 审批通过
              detail = this.$i18n.t("label.manageAll.sta2");
            } else if (this.submitType == "Rejected") {
              // 审批拒绝
              detail = this.$i18n.t("label.manageAll.sta3");
            }
            if (this.edictValue.detailGroupId != "") {
              detailSendCard(this.edictValue.detailGroupId, {
                approval_value: `${localStorage.getItem("username")}${detail}`,
                title: this.$i18n.t("vue_label_chat_approval_message"),
                recordId: this.edictValue.recordId,
                type: "APPROVAL_CARD",
                changer: localStorage.getItem("username"),
                // body: [
                //   {
                //     fieldLable: "日期",
                //     fieldValue: transformTime(new Date().getTime())
                //   }
                // ]
              });
            }
          } else {
            this.$router.push("/approvalCenter");
            this.$Bus.$emit("cancel-not-loading", true);
          }
        } else if (
          result.returnInfo == "Manual" &&
          this.submitType == "Approved"
        ) {
          this.approvalLoading = false;
          if (this.$route.query.isHistory === "2") {
            this.$router.push({
              path: "/approvalCenter/lastApprovalPeo",
              query: {
                option: option,
                isHistory: "2",
                workItemId: this.edictValue.workItemid,
                detailGroupId: this.edictValue.detailGroupId,
                recordId: this.edictValue.recordId,
              },
            });
          } else {
            this.$router.push({
              path: "/approvalCenter/lastApprovalPeo",
              query: { option: option, workItemId: this.edictValue.workItemid },
            });
          }
        } else {
          this.approvalLoading = false;
          this.$message({
            showClose: true,
            type: "warning",
            message: result.stackTrace || result.returnInfo,
          });
          this.$Bus.$emit("cancel-not-loading", true);
        }
      } catch (err) {
        this.approvalLoading = false;
      }
    },
    /*
     * 批准请求
     */
    submitForm: debounce(
      function (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.approvalLoading = true;
            this.submitType = "Approved";
            this.getApprovalList();
          } else {
            return false;
          }
        });
      },
      3000,
      { leading: true, trailing: false }
    ),
    /*
     * 取消请求
     */
    cancel() {
      // this.$router.push("/approvalCenter");
      this.$router.go(-1);
    },
    /*
     * 拒绝请求
     */
    refuse: debounce(
      function (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.approvalLoading = true;
            this.submitType = "Rejected";
            this.rejectApproval();
          } else {
            return false;
          }
        });
      },
      3000,
      { leading: true, trailing: false }
    ),
    /*
     * 设置评分
     */
    setStar(nub, item) {
      this.stars = document.querySelectorAll(`.${item.apiname} span`);

      if (nub === "active") {
        nub = Number(item.value);
      }
      let name = "";
      name = "show2";
      for (var i = 0; i < this.stars.length; i++) {
        // 区分鼠标悬浮和鼠标离开
        this.stars[i].className = i <= nub ? name : "";
      }
    },
    /*
     * 获取当前评分下标
     */
    setClick(item, idx) {
      item.value = idx;
      this.formData[item.apiname] = idx;
    },
    /*
     * 事件任务类型相关项选择
     */
    checkItem(index, item) {
      let option = {};
      // this.fieldId = item.fieldId
      option.fieldId = item.fieldId;
      option.fieldType = item.fieldType;
      if (index == 0) {
        this.eventIndex = index;
        if (this.eventValueA) {
          // option.relevantObjId = this.eventValueA
          option.relevantPrefix = this.eventValueA;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueA) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_selectname")
          );
        }
      } else if (index == 1) {
        this.eventIndex = index;
        if (this.eventValueB) {
          option.relevantPrefix = this.eventValueB;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueB) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_select_first")
          );
        }
      }
    },
    /*
     * 事件查询
     */
    eventSearch(item) {
      this.fieldId = item.fieldId;
      this.relevantObjId = item.relevantObjId;
      this.relevantObjApi = item.relevantObjApi;
      this.relevantPrefix = item.relevantPrefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    getDefaultOption() {
      if (
        this.specialObjectapi === "Event" ||
        this.specialObjectapi === "Task"
      ) {
        CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
          this.defaultPrefix = {
            relate: res.data.relateobjList[0].prefix,
            name: res.data.whoobjInfoList[0].prefix,
          };
          this.formAttrData.forEach((item) => {
            if (item.prop === "whoid") {
              if (item.value) {
                this.eventValueA = item.child.whoobj; // 名称
                this.eventCheckedObjA = {
                  id: item.value,
                  name: item.child.whoname,
                };
              } else {
                this.eventValueA = this.defaultPrefix.name; //  名称
              }
            }
            if (item.prop === "relateid") {
              if (item.value) {
                this.eventValueB = item.child.relateobj; // 相关项
                this.eventCheckedObjB = {
                  id: item.value,
                  name: item.child.relatename,
                };
              } else {
                this.eventValueB = this.defaultPrefix.relate; // 相关项
              }
            }
          });
        });
      }
    },
    /*
     * 名称更改
     */
    eventChangeA(e) {
      this.eventValueA = e;
      this.eventCheckedObjA = {};
    },
    /*
     * 相关项名称更改
     */
    eventChangeB(e) {
      this.eventValueB = e;
      this.eventCheckedObjB = {};
    },
    /*
     * 获取 阶段-可能性 对应关系
     */
    getKNXSelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then(
        (res) => {
          if (res.result && res.returnCode === "1") {
            let options = [];
            res.data.forEach((item) => {
              options.push({
                val: item.codevalue,
                key: item.codekey,
                knx: Number(item.knx),
                forecasttype: item.forecasttype,
              });
            });
            this.possibilityList = options;
          } else {
            this.$message.error(res.returnInfo);
          }
        }
      );
    },
    /*
     * 双向绑定 form name
     */
    filterFormData() {
      this.rules = {};
      this.formData = {};
      this.formAttrData &&
        this.formAttrData.forEach((v) => {
          // 业务机会对象可能性随阶段变化显示对应的值
          if (this.prefix === "002" && v.apiname === "jieduan") {
            this.getKNXSelectValue();
          }

          // 百分比字段显示特殊处理
          if (v.fieldType === "P" && v.value === "") {
            this.$set(this.formData, v.prop, undefined);
          } else if (v.prop === "recordtype") {
            this.$set(this.formData, v.prop, v.id);
          } else {
            this.$set(this.formData, v.prop, v.value);
          }

          // 如果有验证规则
          // 字段必填且不只读时加必填规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            //启用外部用户判断用户值是否是邮箱格式
            if (this.contactes === "contactsNew" && v.apiname === "loginname") {
              this.rules[name].push({ validator: this.isEmail });
            }

            // 邮件、手机添加校验规则
            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }
          }
          //只读时无需判断
          // else if (v.fieldType === "E") {
          //   let arr = [{ validator: this.isEmail }];
          //   this.$set(this.rules, v.prop, arr);
          // } else if (v.fieldType === "P") {
          //   let arr = [{ validator: this.isNumber }];
          //   this.$set(this.rules, v.prop, arr);
          // } else if (
          //   this.contactes === "contactsNew" &&
          //   v.apiname === "loginname"
          // ) {
          //   this.$set(this.rules, v.prop, arr);
          // }
        });

      // 获取任务事件下拉框默认选项
      this.formAttrData && this.getDefaultOption();
      // 事件类型 计算时间差
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        this.timeDiff = this.timeFn(
          this.formData.begintime,
          this.formData.endtime
        );
      }
    },
    /*
     * 重置表单验证
     */
    resetRules() {
      this.rules = {};
      this.formAttrData &&
        this.formAttrData.forEach((v) => {
          // 如果有验证规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          }
        });
    },
    /*
     * 获取表单数据
     */
    getData(callback) {
      // 等待修改
      let index = this.filterOptions.findIndex((item) => {
        return this.formData.company === item.value;
      });
      // 改动原因: 需要判断当前选择的公司是否在会员机构之内
      if (index !== -1) {
        this.formData.isCompany = true;
      } else {
        this.formData.isCompany = false;
      }
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          callback(this.formData);
        } else {
          return false;
        }
      });
    },
    /*
     * 添加文件
     */
    addFile(item) {
      this.$emit("addFile", item);
    },
  /**
     * 打开文件预览
     */
    async openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = item;
      // 传入按钮如果不需要按钮，可以传空数组
      this.$refs.previewFile.handleBtn(["download"]);
    },
    /*
     * 获取文件大小
     */
    queryLimit() {
      CommonObjApi.queryLimit({ type: "file" }).then((res) => {
        this.fileSize = Number(res.data.slice(0, res.data.length - 2)) * 1024;
      });
    },
    /*
     * 清除验证规则
     */
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    /*
     * 查找/查找多选
     */
    remoteSearch(item) {
      this.eventIndex = -1;
      this.relevantObjApi = "";
      this.fieldId = item.id;
      this.relevantObjId = item.objId;
      this.relevantPrefix = item.prefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$emit("notZhucongfield");
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /*
     * 改变查找/查找多选的值
     */
    changeSelect(row) {
      // 如果是加签
      if (this.isInsert) {
        let arrIds = []
        this.formDataInsert.people = []
        let approvalers = {}
        this.selectApprovalers = {}
        if (Array.isArray(row)) {
          row.forEach((item) => {
            arrIds.push(item.id);
            approvalers[item.id] = item.name;
          });
        } else {
          arrIds.push(row.data.id);
          approvalers[row.data.id] = row.data.name;
        }
        this.formDataInsert.people = arrIds;
        this.selectApprovalers = approvalers;
        this.showSearchTable = false;
        return;
      }

      if (this.eventIndex == 0 && !Array.isArray(row)) {
        this.eventCheckedObjA = row.data;
        this.formData.whoobj = this.eventValueA;
        this.eventIndex = -1;
      } else if (this.eventIndex == 1 && !Array.isArray(row)) {
        this.eventCheckedObjB = row.data;
        this.formData.relateobj = this.eventValueB;
        this.eventIndex = -1;
      }
      this.showSearchTable = false;
      this.$parent.isZhucongfield = false;
      this.$emit("changeSelect", row, this.fieldId, this.formData);
    },
    /*
     * 设置查找筛选条件
     */
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttrData.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (
          attr !== undefined &&
          this.formData[attr.prop] !== null &&
          Array.isArray(this.formData[attr.prop])
        ) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined && this.formData[attr.prop] !== null) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    /*
     * 关闭查找弹框
     */
    beforeClose() {
      this.showSearchTable = false;
      this.isInsert = false;
    },
    /*
     * 新增查找、查找多选字段
     */
    newSearchableField(item) {
      // 先查权限再新建
      CommonObjApi.getObjectPermission({ id: item.objId }).then((res) => {
        if (res.data.add && res.data.add) {
          this.$emit("newSearchableField", item);
        } else {
          // 抱歉，您暂无该对象新建权限
          this.$message.warning(this.$i18n.t("message.nopermession"));
        }
      });
    },
    /*
     * 远程搜索方法
     */
    remoteMethod(query, item) {
      if (query !== "") {
        setTimeout(() => {
          let params = {
            name: query,
            objId: item.prefix,
            fieldId: item.fieldId,
            lkdp: "",
            page: 1,
            pageSize: 20,
          };
          this.$emit("remoteMethod", params, item, this.formData);
        }, 200);
      }
    },
    /*
     * 远程搜索完成选择后清空搜索数组
     */
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    /*
     * 远程搜索框失去焦点后清空查询列表
     */
    blurEvent() {
      // this.filterOptions = []
    },
    /*
     * 选项框发生变化
     */
    selectChange() {
      this.$emit("selectChange", arguments);
    },
    /*
     * 更改文件
     */
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    /*
     * 移除文件
     */
    remove() {
      this.$emit("remove", ...arguments);
    },
    success() {},
    /*
     * 内部组件处理鼠标移出事件
     */
    handleBlurInChild(item, val) {
      // this.handleBlur && this.handleBlur(...arguments)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        item.changeEvent !== undefined ? item.changeEvent.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem && this.$emit(eventItem, val, item.fieldId, this.formData);
        } else {
          eventItem && this.$emit(eventItem);
        }
      });
    },
    /*
     * 获取 阶段-可能性 对应关系
     */
    // getKNXSelectValue(selectVal) {
    //   CommonObjApi.getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then(
    //     (res) => {
    //       if (res.result && res.returnCode === "1") {
    //         res.data.forEach((item) => {
    //           if (item.codekey == selectVal) {
    //             this.formData.knx = item.knx;
    //             this.formData.forecasttype = item.forecasttype;
    //           }
    //         });
    //       }
    //     }
    //   );
    // },
    /*
     * 值发生改变时候执行的事件
     */
    changeEvent(callback, selectVal, selectItem, fieldType) {
      //如果下拉框为阶段时需要动态更改可能性及预测值
      // if (selectItem.name == "jieduan") {
      //   this.getKNXSelectValue(selectVal);
      // }
      //项目管理弹窗上添加提示
      if (
        (selectItem.apiname === "inform_principal" ||
          selectItem.apiname == "notice_principal") &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择否时，将无法通知负责人！
        this.$alert(
          this.$i18n.t("label.projectManagement.the.person.not.be.informed")
        );
      }
      if (
        selectItem.apiname === "remind_principal" &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择“否”时，提醒频率将无法生效
        this.$alert(
          this.$i18n.t("label.projectManagement.alert.frequency.will.not.work")
        );
      }
      if (selectItem.label === "完成百分比" && selectVal == "0%") {
        this.formData.status = "未开始"; //"未开始"
        this.formData.task_status = "未开始"; //"未开始"
      } else if (selectItem.label === "完成百分比" && selectVal == "100%") {
        this.formData.status = "完成"; //完成
      }
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      if (this.prefix === "002" && selectItem.apiname === "jieduan") {
        let knx = this.possibilityList.find((item) => {
          return item.val === selectVal;
        });
        if (knx !== undefined) {
          // 强制改可能性和预测类别的值 --- 接口处理
          // this.$set(this.formData, 'knx', knx.knx)
          // this.$set(this.formData, 'forecasttype', knx.forecasttype)
          if ("knx" in this.formData) {
            this.formData["knx"] = knx.knx;
          }
          if ("forecasttype" in this.formData) {
            this.formData["forecasttype"] = knx.forecasttype;
          }
        }
      }

      // 日期时间字段处理
      if (fieldType === "datetime") {
        if (
          this.formData[`${selectItem.prop}Date`] &&
          this.formData[`${selectItem.prop}Time`]
        ) {
          this.$set(
            this.formData,
            selectItem.prop,
            `${this.formData[`${selectItem.prop}Date`]} ${
              this.formData[`${selectItem.prop}Time`]
            }`
          );
        } else {
          this.$set(this.formData, selectItem.prop, "");
        }
      }
      // callback && callback(selectVal, selectItem, this.formData)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      if (selectItem.prop !== "recordtype") {
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            eventItem &&
              this.$emit(
                eventItem,
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else {
            eventItem &&
              this.$emit(eventItem, selectVal, selectItem, this.formData);
          }
        });
      }
      // 事件类型自动更改结束时间
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        // 如果更改项为开始时间自动更改结束时间
        if (selectItem.prop === "begintime") {
          this.autoEventDate(this.formData.begintime);
        }
        // 如果更改项为结束时间重新计算时间差
        if (selectItem.prop === "endtime") {
          if (
            this.timeFn(this.formData.begintime, this.formData.endtime) >= 0
          ) {
            this.timeDiff = this.timeFn(
              this.formData.begintime,
              this.formData.endtime
            );
          } else {
            this.formData.endtime = "";
            this.$message({
              showClose: true,
              type: "warning",
              // '结束时间不应早于开始时间'
              message: this.$i18n.t("label.appointment.wizard.notif1"),
            });
          }
        }
      }
    },
    /*
     * 计算时间差
     */
    timeFn(d1, d2) {
      //di作为一个变量传进来
      let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
      return dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
    },
    /*
     * 满足条件时自动更改结束时间
     */
    autoEventDate(s) {
      let dateBegin = new Date(s.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = dateBegin.getTime() + this.timeDiff;
      //时间戳转换方法    d:时间戳数字
      function formatDate(d) {
        let date = new Date(d);
        let YY = date.getFullYear() + "-";
        let MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        let mm =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        let ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      }
      this.formData.endtime = formatDate(dateEnd);
    },
    /*
     * 获取焦点时触发事件
     */
    focusEvent() {},
    /*
     * 富文本内容变更
     */
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    selecter(cb, data) {
      cb(data);
    },
    /*
     * 下拉选项框出现时候触发
     */
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
    },
    /*
     * 修改某个属性的键值
     */
    changeProps(props, key, val) {
      for (let i = 0; i < this.formAttrData.length; i++) {
        let item = this.formAttrData[i];
        if (item.prop === props) {
          item[key] = val;
          break;
        }
      }
    },
    listenStorage() {
      window.addEventListener("storage", this.listenStorageFn);
    },
    listenStorageFn(event) {
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        let option = {
          value: localStorage.getItem("relevantObjectDataId"),
          label: localStorage.getItem("relevantObjectDataName"),
        };
        this.$emit(
          "addFieldOption",
          localStorage.getItem("relevantObjectApi"),
          option
        );
      }
    },

    /*
     * 天眼查
     */
    tianyan() {
      this.$refs.tian.names(this.formData.name);
      this.$refs.tian.handleCloses();
    },
    /*
     * 回填客户名
     */
    setTianyanValue(val) {
      let formAttrs = [];
      this.formAttrData.forEach((item) => {
        if (item.apiname === "name") {
          item.value = val;
          formAttrs.push(item);
        } else {
          formAttrs.push(item);
        }
      });

      this.formAttrData = formAttrs;
      this.filterFormData();
    },
    /*
     * 回填全部数据
     */
    setTianyanValuees(val) {
      this.formAttrData.forEach((item) => {
        val.forEach((items) => {
          if (item.label === items.name) {
            item.value = items.value;
          }
        });
      });

      this.filterFormData();
    },
    /*
     * 获取当前用户信息
     */
    async getUserInfo() {
      let res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.userInfo = res.data;
      }
    },
    /*
     * 手动输入值
     */
    selectBlur(e, item) {
      this.formData[item.prop] = e.target.value;
    },
    itemFormat(item) {
      return {
        ...item,
        defaultValue: item.defaultvalue,
      };
    },
    /*
     * 打开加签弹窗
     */
    openInsertApproval() {
      this.dialogApproval = true;
    },
    /**
     * 点击分配加签
     */
    approvalDefineClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approvalDefine();
        } else {
          return false;
        }
      });
    },
    /**
     * 加签请求接口
     */
    approvalDefine: debounce(
      function () {
        let option = {}
        if (this.formDataInsert.people.length > 1) {
          option.insertApprovalType = this.formDataInsert.ApprovalType
        } else {
          option.insertApprovalType = "apprroutingapprfirstrouting"
        }
        let people = this.formDataInsert.people.join(",");
        option.workItemId = this.edictValue.workItemid;
        option.comments = this.formDataInsert.remarks;
        option.fprId = people;
        approvalSave(option).then(res => {
          if (res.result) {
            this.dialogApproval = false;
            if (this.$route.query.isHistory === "2") {
            this.$router.go(-1);
          } else {
            this.$router.push("/approvalCenter");
            this.$Bus.$emit("cancel-not-loading", true);
          }
          }
        }).catch((err)=>{
          this.$message.error(err.data)
        })
        // window.location.reload();
      },
      3000,
      { leading: true, trailing: false }
    ),
    /**
     * 取消弹窗之前
     */
    beforeCloseInsert() {
      this.formDataInsert = {
        people: [],
        remarks: "",
        ApprovalType: "apprroutingapprallrouting",
      };
      this.optionCheckedArr = [];
      this.dialogApproval = false;
    },
    // 加签查找用户
    remoteSearchInsert() {
      this.isInsert = true;
      this.relevantObjApi = "";
      this.fieldId = "";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.checked = true;
      this.optionCheckedArr = [];
      this.formDataInsert.people.forEach(item => {
        let obj = {
          id: item,
          name: this.selectApprovalers[item],
        }
        this.optionCheckedArr.push(obj);
      })
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 获取加签按钮状态
     */
    getApprovalPreview() {
      let option = {
        relatedId: this.id,
      };
      getApprovalPre(option)
        .then((res) => {
          if (res.result) {
            if (res.data.allowedInsertApproval === "false") {
              this.btnInsert = false;
            } else if (res.data.allowedInsertApproval === "true") {
              this.btnInsert = true;
            }
          }
        })
    },
    /**
     * 删除审批人
     */
    delApprovaler(item) {
      this.formDataInsert.people = this.formDataInsert.people.filter(it => {
        return it !==item
      })
    }

  },
  watch: {
    formAttr: {
      handler: function () {
        if (this.autoRefresh) {
          this.filterFormData();
        }
      },
      deep: true,
    },
    routerValue: {
      handler: function (newVal){
        if(newVal && Object.prototype.toString.call(newVal) === '[object Object]' ){
          this.edictValue = Object.assign({},newVal)
        }
      },
      immediate: true
    },
  },
  computed: {
    /**
     * 表单数据
     */
    formAttrData() {
      let attr = this.formAttr;
      let formAttrData = JSON.parse(JSON.stringify(attr));
      return formAttrData;
    },
    /**
     * 每个输入框的判断，
     * 使用计算属性，增加公海池的所属公海池，有值就不能编辑的判断
     */
    inputCheckIsDisabled() {
      return (item) => {
        if (item.apiname === "marketsea") {
          // 如果是公海池对象
          if (item.value && this.isClientPoolEditStatus) {
            // 如果所属公海池对象有值，则禁用，不让其编辑
            return true;
          } else {
            // 否则则让其编辑
            return false;
          }
        } else {
          // 如果不是公海池则返回后台的判断行为
          return !item.edit;
        }
      };
    },
    /**
     * 下拉选项的判断，
     * 增加公海池的所属公海池的下拉选项，去掉【新建】的选项
     */
    selectCheckIsHiddenNewbulid() {
      return (item) => {
        if (item.objId === "marketsea") {
          return false;
        } else if (item.objId !== "user") {
          return true;
        }
      };
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.df {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
::v-deep .big_map {
  width: 80px;
  height: 20px;
  line-height: 1px;
  cursor: pointer;
  padding: 12px 10px;
  span {
    font-size: 12px;
  }
}

::v-deep .big-view-box {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    .bm-view-big {
      width: 100%;
      height: 550px;
    }
    .btn_map {
      position: absolute;
      top: 16px;
      right: 90px;
      z-index: 9;
      button {
        height: 44px;
      }
    }
  }
}
.base-form {
  margin-top: 73px;
}
.linkcss {
  color: #006dcc;
}
// 表单只有一个字段时，居中显示
.center-form {
  padding: 0 25%;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 40px;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  right: 25px;
  .el-icon-arrow-up {
    display: none;
  }
}

::v-deep .no-suffix {
  .el-input__inner {
    cursor: text;
  }
}

.remoteBtn {
  position: absolute;
  right: 8px;
  top: 1px;
  color: #c0c4cc;
  z-index: 100;
  cursor: pointer;
}

.helpImg {
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
}

.groupTitle {
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}

::v-deep .el-form-item__content .content {
  word-break: break-word;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}

.el-input-number {
  width: 100%;

  ::v-deep .el-input__inner {
    text-align: left;
  }
}

::v-deep .el-form-item__label {
  word-break: break-word;
  margin-top: 8px;
  padding-left: 50px;
  line-height: 24px !important;
}

::v-deep .el-select__tags {
  margin-top: 5px;
  top: 3%;
  transform: translateY(0%);
}

::v-deep .el-input__inner {
  height: 30px;
}
.event-item {
  display: flex;
  align-items: baseline;
  .event-item-l {
    width: 200px;
    height: 40px;
  }
  .event-item-r {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    //color: #c3c7cf;
    font-size: inherit;
    height: 30px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

::v-deep .tianyancha {
  width: 85% !important;
  margin-right: 10px !important;
}

::v-deep .dis {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
}
.el-button--default {
  // padding: 8px 15px;
  color: #006dcc;
  font-size: 12px;
}
form {
  margin: 33px 0 21px 0;
}
.deletetext {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    opacity: 0;
    cursor: pointer;
  }
}
.deletetext:hover {
  .icon {
    opacity: 1;
  }
}
.insert-box{
  ::v-deep form {
    margin-top: 0 !important;
  }
  ::v-deep .el-form-item__label {
    padding-left: 0 !important;
  }
}
.checked-approvaler{
  display: flex;
  flex-wrap: wrap;
  .approvaler{
    position: relative;
    margin-right: 20px;
    .header{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #43a0ff;
      color: #fff;
      text-align: center;
      margin-bottom: 8px;
      line-height: 48px;
    }
    .name{
      height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #080707;
      line-height: 16px;
      text-align: center;
    }
    .del-icon {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 16px;
      visibility: hidden;
      cursor: pointer;
    }
  }
  .approvaler:hover {
    .del-icon {
      visibility: visible;
    }
  }
  .approvaler-option{
    margin-right: 20px;
    .approvaler-add{
      cursor: pointer;
      width: 48px;
      height: 48px;
      background: #FFFFFF;
      opacity: 1;
      border: 1px solid #E1E1E1;
      color: #BBBBBB;
      opacity: 1;
      font-size: 32px;
      border-radius: 50%;
      text-align: center;
      line-height: 48px;
      margin-bottom: 8px;
    }
    .name{
      height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #080707;
      line-height: 16px;
      text-align: center;
    }
  }
}

.allow-click {
  color: #006dcc;
}
</style>
