var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataList.length > 0),expression:"dataList.length > 0"}]},[_c('div',{staticClass:"shadow"}),_c('div',{staticClass:"approval-relativeList"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"list_box"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.objLabel)+" "),(
            _vm.PermissionById.isEdit &&
            item.permission &&
            item.objid !== 'AccountTeam20210326' &&
            item.objid !== '201603100108754gRole' &&
            item.objid !== '201603071422694Oppor' &&
            item.objid !== '2011000000062730EI25' &&
            item.objid !== 'track' &&
            item.objid !== '2013000001473776JtrA' &&
            item.objid !== 'attachement' &&
            item.objid !== '201609081008384marks'
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.createdit(item)}}},[_vm._v(_vm._s(_vm.$t("label.custom.new")))]):_vm._e()],1),(item.objid === 'attachement' && _vm.isAddWork)?_c('el-button',{staticClass:"add_work",attrs:{"size":"small"},on:{"click":_vm.addWorkFun}},[_vm._v(_vm._s(_vm.$t("label.file.library.selectFile2Lib")))]):_vm._e(),_c('el-table',{attrs:{"border":"","empty-text":_vm.$t('label.dashboard.noRecord'),"data":item.data}},_vm._l((item.fieldList),function(Sitem,Sindex){return _c('el-table-column',{key:Sindex,attrs:{"height":"30px","label":Sitem.label,"prop":Sitem.type == 'R' || Sitem.type == 'Y' || Sitem.type == 'M'
              ? Sitem.name + 'ccname'
              : Sitem.name,"class-name":_vm.isJump(Sitem.type, Sitem.name, item.data) ? 'relativeItem' : ''},on:{"click":function($event){return _vm.detailTurn(Sitem.type, Sitem.name, item.data)}},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.column.className == '' && Sitem.type !== 'N')?_c('span',{domProps:{"innerHTML":_vm._s(scope.row[scope.column.property])}}):(scope.column.className == '' && Sitem.type === 'N')?_c('span',[_vm._v(_vm._s(_vm.handleNum(scope.row[scope.column.property],Sitem)))]):_vm._e(),_c('a',{directives:[{name:"show",rawName:"v-show",value:(scope.column.className != ''),expression:"scope.column.className != ''"}],attrs:{"href":scope.column.className != ''
                  ? scope.row.attach_type
                    ? (_vm.baseURL + "/file/downloadAttachement?accessToken=" + _vm.token + "&id=" + (scope.row.id))
                    : scope.column.property === 'name'
                    ? ("#/commonObjects/detail/" + (scope.row.id) + "/DETAIL")
                    : scope.column.property.indexOf('ccname')
                    ? ("#/commonObjects/detail/" + (scope.row[
                          scope.column.property.slice(
                            0,
                            scope.column.property.length - 6
                          )
                        ]) + "/DETAIL")
                    : ("#/commonObjects/detail/" + (scope.row[scope.column.property]) + "/DETAIL")
                  : ''}},[_vm._v(" "+_vm._s(scope.row[scope.column.property])+" ")])]}}],null,true)})}),1)],1)}),0),_c('create-edit-obj',{ref:"createEditObj",attrs:{"objectApi":_vm.tabName,"relationFieldId":_vm.id,"prefix":_vm.prefix,"isTableEdit":true,"relativeRecordId":_vm.routerValue.objid},on:{"update":_vm.getApoprovalList}}),_c('add-files',{ref:"atoLib",attrs:{"isFromFileCenter":false,"dialogAddVisible":_vm.dialogAddVisible},on:{"addSubmit":_vm.addWorkSubmit,"close":_vm.addWorkClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }