<template>
  <div class="approval-request">
    <div class="approval-body">
      <div class="approval-title">
        <div class="tltle">
          <!-- 批准请求 -->
          {{ $t("label.approvereqiest") }}
        </div>
        <div class="user">
          <span @click="goToDetail" v-show="fromDetail == 0">{{
            recordName
          }}</span>
          <span @click="goBackDetail" v-show="fromDetail == 2">{{
            recordName
          }}</span>
          <!-- 若要查看当前记录的详细信息，请点左侧链接 -->
          {{ $t("label.process.des") }}
        </div>
      </div>
      <div class="approval-body">
        <div class="title">
          <!-- 批准/拒绝请求 -->
          {{ $t("label.approveorrefuserequest") }}
        </div>
        <div class="body" v-loading="loading">
          <base-form
            ref="baseForm"
            class="baseForm"
            :id="historyId"
            :isShow="isShow"
            :form-attr="formAttr"
            :prefix="prefix"
            :columnNum="columnNum"
            :oneColumnWidth="oneColumnWidth"
            :twoColumnWidth="twoColumnWidth"
            :option-list="optionList"
            :status-icon="statusIcon"
            :autoRefresh="autoRefresh"
            :routerValue="routerValue"
            @changeSelect="changeSelect"
            @addFile="addFile"
            @control="control"
            v-if="isFormDataPushComplete"
          >
          </base-form>
        </div>
      </div>
    </div>
    <div class="shuttle-box">
      <el-dialog
        :title="$t('label.selectapprover')"
        :visible.sync="isSearch"
        width="500px"
      >
        <shuttle-box @changeSearch="changeSearch"></shuttle-box>
      </el-dialog>
    </div>
    <!-- 审批相关列表 -->
    <relativeList :routerValue="routerValue"></relativeList>
    <ApprovalHistory
      class="approval-history"
      :historyID="historyId"
      :showHeader="true"
    ></ApprovalHistory>
    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
  </div>
</template>

<script>
// 客户端脚本
import {
  listClientScript,
  myOnLoad,
  specialPageIdList,
} from "@/utils/ccdk/clientScript.js";
/*
 * 审批详情页面
 */
import axios from "axios";
import Bus from "@/mixin/bus.js";
import shuttleBox from "@/views/approval/components/shuttle-box.vue";
// import batchList from "@/views/approval/components/batch-list.vue";
import {
  getProcessInstanceWorkitem,
  getSelectValue,
  getLookupRelatedFieldValue,
} from "./api.js";
import BaseForm from "@/views/approval/components/base-form.vue";
import INPUTTYPE from "@/config/enumCode/inputType";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
import { AddFiles } from "@/components/index";
import relativeList from "@/views/approval/components/approval-relativeList.vue";
axios.defaults.timeout = 50000;
axios.defaults.baseURL = window.Glod["ccex-apitsf"] + "/api";

export default {
  name: "approval-page",
  data() {
    return {
      //当前审批名称
      recordName: "",
      //对象前缀
      prefix: "",
      //表单当前类型
      dialogAttr: {
        title: "",
        type: "EDIT",
        isShow: false,
        dialogWidth: "85%",
      },
      //是否为主从字段
      isZhucongfield: false,
      // 查找字段
      lookupValues: [],
      // 单一审核是否显示拒绝按钮
      isShow: true,
      //控制添加文件弹窗显示与影藏
      dialogAddVisible: false,
      //页面loading
      loading: true,
      //是否搜索下一个审批人
      isSearch: false,
      //表单数据
      formData: {
        people: "",
        remarks: "",
      },
      //审批预览id
      historyId: JSON.parse(this.$route.query.value).objid,
      //判断从什么页面跳转到审批页面
      fromDetail: this.$route.query.isHistory || 0,
      //路由获取到的值
      routerValue: JSON.parse(this.$route.query.value),
      //提交的类型
      submitType: "",
      //表单数据
      formAttr: null,
      //存储查找字段和选项列表字段数组
      optionList: {},
      //是否展示状态图标
      statusIcon: true,
      //值发生变化时是否自动刷新
      autoRefresh: false,
      //依赖关系绑定事件
      controlContainer: null,
      //判断页面是否需要刷新
      pageChange: false,
      //文件prop
      fieldProp: "",
      //审批表单布局列数
      columnNum: 2,
      //审批表单一列宽度
      oneColumnWidth: "100%",
      //审批表单两列宽度
      twoColumnWidth: "50%",
      //当前表单数据下标
      attrIndex: -1,
      // formData的数据是否处理完成
      isFormDataPushComplete: false,
    };
  },
  computed: {},
  components: {
    shuttleBox,
    // batchList,
    BaseForm,
    ApprovalHistory,
    AddFiles,
    relativeList,
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    // 监听窗口变化
    Bus.$on("windowResize", this.resizeCallback);
    if (this.$route.path == "/approvalCenter/approvalRequest") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
      this.getApprovalPreview();
    }
    // this.$Bus.$emit("deliver-edict-approval", this.routerValue);
    this.getFormAttr();
    this.listMyOnload();
  },
  activated() {
    // this.$Bus.$emit("deliver-edict-approval", this.routerValue);
    this.$Bus.$on("approval-show-or-hidden", this.approvalShowOrHideCallback);
    if (this.pageChange == false) {
      this.getFormAttr();
      this.loading = true;
    } else {
      this.routerValue = this.$route.query.value;
      this.historyId = this.$route.query.value.objid;
      this.$Bus.$emit("deliver-history-id", this.historyId);
      this.historyId;
      this.getFormAttr();
      this.pageChange = false;
      this.loading = true;
    }
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.resizeCallback);
    this.$Bus.$off("approval-show-or-hidden", this.approvalShowOrHideCallback);
  },
  methods: {
    resizeCallback() {
      this.calcDetailInfoWidth();
    },
    approvalShowOrHideCallback() {
      this.pageChange = true;
    },
    /**
     * 客户端脚本onload事件
     */
    listMyOnload() {
      let loadParam = {
        pageId: "list",
        event: "onLoad",
        objId: specialPageIdList.approval,
        recordTypeId: "",
      };
      listClientScript(loadParam, myOnLoad);
    },
    /*
     * 计算详细信息宽度
     */
    calcDetailInfoWidth() {
      let width = document.getElementsByClassName("body")[0].clientWidth;
      if (width <= 960) {
        this.setColumnsMode("twoColumns");
      } else if (960 < width && width <= 1440) {
        this.setColumnsMode("fourColumns");
      } else if (width > 1440) {
        this.setColumnsMode("sixColumns");
      }
    },
    /*
     * 246列布局设置
     */
    setColumnsMode(detailMode) {
      switch (detailMode) {
        case "twoColumns":
          this.columnNum = 2;
          this.oneColumnWidth = "100%";
          this.twoColumnWidth = "50%";
          break;
        case "fourColumns":
          this.columnNum = 4;
          this.oneColumnWidth = "50%";
          this.twoColumnWidth = "25%";
          break;
        case "sixColumns":
          this.columnNum = 6;
          this.oneColumnWidth = `${100 / 3}%`;
          this.twoColumnWidth = `${100 / 6}%`;
          break;
      }
    },
    /*
     * 判断单一审核是否有拒绝权限
     */
    getApprovalPreview() {
      let option = {
        relatedId: this.historyId,
      };
      axios
        .post(`approval/getGotoApprovalPreview`, option, {
          headers: {
            accesstoken: this.$CCDK.CCToken.getToken(),
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.result) {
            this.isShow = data.data.allowedReject !== "false";
          } else {
            this.message.error(data.returnInfo);
          }
        })
        .catch((err) => {
          if (err && err.returnInfo) {
            this.message.error(err.returnInfo);
          }
        });
    },
    /*
     * 添加文件
     */
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    /*
     * 添加文件数据处理
     */
    addWorkSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        ids.push(file.id);
      });
      // 文件上传个数超出时给提示
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          if (
            attr.fieldProperty &&
            attr.fieldProperty.defaultvalue &&
            ids.length > Number(attr.fieldProperty.defaultvalue)
          ) {
            this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
            this.$refs.addFile.buttonLoading = false;
          } else {
            attr.dataFile = checkedFileList;
            this.$set(
              this.$refs.baseForm.formData,
              this.fieldProp,
              ids.toString()
            );
            this.addWorkClose();
          }
        }
      });
    },
    /*
     * 关闭添加文件
     */
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    /*
     * 表单添加属性
     */
    addFormAttr(title, colNum, attr, isChildren, position) {
     
      this.attrIndex += 1;
      this.$set(attr, "attrIndex", this.attrIndex);
      this.$set(attr, "colNum", 1);
      attr.value = attr.fieldValue;
      attr.fieldLabel = attr.label;
      attr.fieldType = attr.type;
      attr.apiname = attr.name;
      attr.maxlength = attr.length;
      attr.fieldLength = attr.length;
      // 除图片类型外，选项列表options及默认值赋值
      if (attr.fieldType != "IMG") {
        attr.defaultValue = attr.fieldValue;
        attr.data = attr.options;
      }
      if (attr.id) {
        attr.fieldId = attr.id;
      }
      if (attr.readonly == "1") {
        attr.edit = false;
      } else {
        attr.edit = true;
      }
      if (attr.edit == false) {
        attr.readonly = "1";
      } else {
        attr.readonly = "0";
      }
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      if (attr.required == "1" && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel
            }`,
            trigger: "blur",
            // 请输入
          },
        ];
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      // 给字段设置默认值
      // 非查找/查找多选字段
      if (attr.defaultValue && attr.defaultValue !== "") {
        attr.value = attr.defaultValue;
      }
      if (
        (attr.fieldType !== "AD" && attr.fieldType !== "LT") ||
        (attr.fieldType == "AD" && attr.address == undefined)
      ) {
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? Number(attr.fieldProperty.decimalPlaces)
              : 0;
          let decimal =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? 1 / Math.pow(10, Number(attr.fieldProperty.decimalPlaces))
              : 0;
          let valueIntervalMax =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? attr.fieldProperty.decimalPlaces
              : 0;
          attr.valueInterval = {
            min: 0 - Math.pow(10, attr.length - 0 - 1) + decimal,
            max: Math.pow(10, attr.length - valueIntervalMax - 1) - decimal,
          };
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined
        ) {
          if (attr.value == "") {
            attr.data = [];
          }
        }
        // 评分新建时评分设置为-1，其余当前评分-1
        if (attr.type === "score") {
          attr.value = Number(attr.value);
        }
        // 选项列表和选项列表多选
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];

          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.id,
                val: item.value,
              });
            });
          if (attr["name"].slice(attr["name"].length - 2) === "00") {
            this.getCountrySelectValue(attr["name"]);
          } else {
            this.optionList[attr.prop] = options;
          }
          // 设置表单记录类型选项
          if (attr.prop === "recordtype") {
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          if (attr.type === "multi-select") {
            attr.value =
              // 无
              attr.value ===
                `--${this.$i18n.t("label.data.newtemplate.null")}--` ||
              attr.value === "" ||
              attr.value === null ||
              attr.value === undefined
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 赋值默认值方法
          // 查找多选字段选项设置
          attr.data = [];
          attr.data[0] = { id: attr.lookupfieldid, value: attr.fieldValue };
          if (
            attr.fieldType === "MR" &&
            attr.value &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // 无
            attr.value =
              attr.value ===
                `--${this.$i18n.t("label.data.newtemplate.null")}--` ||
              attr.lookupfieldid === ""
                ? []
                : attr.lookupfieldid.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
              attr.value = attr.lookupfieldid;
            } else {
              if (
                attr.apiname === "marketsea" &&
                // this.dialogAttr.type === "NEW" &&
                this.isClientPoolEditStatus === false
              ) {
                // 公海池的特殊字段，新建时才默认赋值
                // 公海池的新建时 选中默认的当前的公海池 option
                multiOptions.push({
                  label: this.currentClientPoolSelectObj.name,
                  value: this.currentClientPoolSelectObj.id,
                });
                attr.value = this.currentClientPoolSelectObj.id;
              } else {
                attr.value = attr.fieldType === "MR" ? [] : "";
              }
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }

          // 新建时设置默认值和选项
          if (
            attr.defaultValue &&
            attr.defaultValue !== "" &&
            attr[`${attr.apiname}Name`] &&
            attr[`${attr.apiname}Name`] !== "" &&
            (attr.fieldType === "Y" || attr.fieldType === "M")
          ) {
            let option = [
              {
                label: attr[`${attr.apiname}Name`],
                value: attr.defaultValue,
              },
            ];
            this.$set(attr, "value", attr.defaultValue);
            this.$set(this.optionList, attr.prop, option);
          }
        } else if (attr.type === "number") {
          attr.value = Number(attr.value.replace(/,/g, ""));
          attr.precision =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? Number(attr.fieldProperty.decimalPlaces)
              : 0;
          let decimal =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? 1 / Math.pow(10, Number(attr.fieldProperty.decimalPlaces))
              : 0;
          let valueIntervalMax =
            attr.fieldProperty !== undefined &&
            attr.fieldProperty !== null &&
            attr.fieldProperty.decimalPlaces
              ? attr.fieldProperty.decimalPlaces
              : 0;
          attr.valueInterval = {
            min: 0 - Math.pow(10, attr.length - 0 - 1) + decimal,
            max: Math.pow(10, attr.length - valueIntervalMax - 1) - decimal,
          };
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.groupTitle = title;
        attr.groupShow = true;

        // 标签和新建时自动编号字段不展示，空位留出
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        if (attr.type == "LT" && attr.location) {
          attr.children = attr.location;
        }
        if (attr.type == "AD" && attr.address) {
          attr.isContrField = true;
          // 地址和地理定位特殊处理
          let newAdress = {
            edit: attr.edit,
            fieldId: "",
            label: this.$i18n.t("LT"), //地理定位
            name: attr.name,
            required: false,
            type: "S",
            isAdress: true,
            mapFlag: true,
          };
          attr.address.unshift(newAdress);
          attr.children = attr.address;
        }
        this.attrIndex -= 1;
        if (attr.children) {
          this.isFormDataPushComplete = false;
          attr.children.forEach((item) => {
            item.fieldValue = item.value;
            // 经纬度类型字段 子字段数字长度和小数点位数从父字段中获取
            if (attr.type == "LT") {
              item.fieldProperty = {};
              item.fieldProperty.fieldLength = attr.length;
              item.fieldProperty.decimalPlaces = attr.decimalPlaces;
            } else {
              item.isContrField = true;
            }
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            this.addFormAttr(title, colNum, item, true);
            // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
            if (item["name"].slice(item["name"].length - 2) === "00") {
              this.getCountrySelectValue(item["name"]);
            }
          });
          this.isFormDataPushComplete = true;
          this.attrIndex -= attr.children.length - 1;
        }
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
      if (attr.prop === "role") {
        let options = [];
        let obj = {};
        attr.roleList.forEach((item) => {
          obj = {
            label: item.rolename,
            value: item.roleid,
          };
          options.push(obj);
        });
        this.$set(this.optionList, attr.prop, options);
      }
      if (attr.prop === "profile") {
        let options = [];
        let obj = {};
        attr.profileList.forEach((item) => {
          obj = {
            label: item.profilename,
            value: item.id,
          };
          options.push(obj);
        });
        //
        this.$set(this.optionList, attr.prop, options);
      }
      // 如果为事件或任务类型，默认分配当前登录用户，特殊处理名称及相关项
      if (
        this.objId === "event" ||
        this.objId === "task" ||
        this.objectApi === "Event" ||
        this.objectApi === "Task"
      ) {
        // 如果操作为新建，填充部分默认值
        if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            if (this.calendarUser.userId) {
              attr.value = [this.calendarUser.userId];
              this.optionList.belongtoid = [
                {
                  value: this.calendarUser.userId,
                  label: this.calendarUser.userName,
                },
              ];
            } else {
              attr.value = [this.currentUserInfo.id];
              this.optionList.belongtoid = [
                {
                  value: this.currentUserInfo.id,
                  label: this.currentUserInfo.name,
                },
              ];
            }
            this.getRelatedItem();
          } else if (attr.prop === "begintime") {
            attr.value = this.eventDate.start || this.getHour().start;
          } else if (attr.prop === "endtime") {
            attr.value = this.eventDate.end || this.getHour().end;
          } else if (attr.prop === "whoid") {
            // 判断对象是否事件/任务
            if (this.objectApies !== "") {
              if (
                this.objectApies === "Lead" ||this.objectApies === "cloudcclead" ||
                this.objectApies === "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    whoname: this.bigTitle[0].fieldValue,
                    whoobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            }

            // 判断是否主页事件、任务等要赋值的对象
            else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid === "Lead" ||this.homeNweObjid === "cloudcclead" ||
                this.homeNweObjid === "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    whoname: this.homeNweName,
                    whoobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            }

            // 来自详情页跳转日历新建事件，回显处理
            else if (this.calendarData.id) {
              if (
                this.calendarData.objId === "Lead" ||
                this.calendarData.objId === "cloudcclead" ||
                this.calendarData.objId === "Contact"
              ) {
                let child = {};
                let obj = {
                  whoname: this.calendarData.name,
                  whoobj: this.calendarData.tabName,
                };
                child = obj;
                attr.child = child;
                attr.value = this.calendarData.id;
              }
            }
          } else if (attr.prop === "relateid") {
            if (this.objectApies !== "") {
              if (
                this.objectApies !== "Lead" &&
                this.objectApies !== "cloudcclead" &&
                this.objectApies !== "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    relatename: this.bigTitle[0].fieldValue,
                    relateobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            } else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid !== "Lead"  &&
                this.homeNweObjid !== "cloudcclead"  &&
                this.homeNweObjid !== "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    relatename: this.homeNweName,
                    relateobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            } else if (this.calendarData.id) {
              if (
                this.calendarData.objId !== "Lead" &&
                this.calendarData.objId !== "cloudcclead" &&
                this.calendarData.objId !== "Contact"
              ) {
                attr.child = {
                  relatename: this.calendarData.name,
                  relateobj: this.calendarData.tabName,
                };
                attr.value = this.calendarData.id;
              }
            }
          }
        } else {
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            attr.value = [attr.value];
            this.getRelatedItem();
          }
        }
      }
      // 强制刷新表单
      this.$refs.baseForm && this.$refs.baseForm.filterFormData();
    },
    /*
     * 获取国家或地区选项列表值
     */
    getCountrySelectValue(filedName) {
      getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /*
     * 表单数据处理
     */
    getFormAttr() {
      this.formAttr = [];
      this.optionList = {};
      this.attrIndex = -1;
      let params = {
        relatedId: this.routerValue.objid,
        workItemId: this.routerValue.workItemid,
        actionType: "process",
      };

      getProcessInstanceWorkitem(params).then((res) => {
        this.loading = false;
        this.calcDetailInfoWidth();

        if (res.returnCode == "10001") {
          this.$message({
            showClose: true,
            type: "warning",
            message: "系统异常，请联系管理员",
          });
          if (this.$route.query.isHistory === "2") {
            this.$router.go(-1);
          } else {
            this.$router.push("/approvalCenter");
          }
          return false;
        }
        this.recordName = res.data.recordName;
        this.$Bus.$emit("deliver-objectApiName", res.data.objectApiName);
        this.$Bus.$emit("deliver-advice-prop", res.data.opinionRequried);
        this.isFormDataPushComplete = false;
        res.data.fieldInfoList.forEach((group) => {
          // 字段属性
          if(group.fieldProperty){
            group.isContrField = !!group.fieldProperty.isContrField
          }
          this.addFormAttr(group.label, 1, group);
        });
        this.isFormDataPushComplete = true;
        let required = res.data.opinionRequried == "1" ? "1" : "0";
        let obj = {
          colNum: 1,
          fieldLabel: this.$i18n.t("label.approval.remark"),
          fieldType: "advice",
          apiname: "advice",
          value: "",
          label: this.$i18n.t("label.approval.remark"),
          name: "advice",
          prop: "advice",
          type: "advice",
          fieldValue: "",
          readonly: "0",
          edit: true,
          required: required,
          groupShow: true,
        };
        if (res.data.opinionRequried == "1") {
          obj.rules = [
            {
              required: "1",
              message: `${this.$i18n.t(
                "label_tabpage_placeenterz"
              )}${this.$i18n.t("label.approval.remark")}`,
              trigger: "blur",
            },
          ];
        }
        this.formAttr.push(obj);
        // 强制刷新表单
        this.$refs.baseForm && this.$refs.baseForm.filterFormData();
        // 查找带值，绑定事件
        if (res.data.fieldList !== null && res.data.fieldList !== undefined) {
          this.setLookupValue(JSON.parse(res.data.fieldList));
        }
        // // 依赖关系，绑定事件
        this.controlContainer =
          res.data.controlContainer != undefined
            ? JSON.parse(res.data.controlContainer)
            : [];
        this.setControlContainer();
      });
    },
    /*
     * 查找带值字段
     */
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.isZhucongfield = false;
            this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    /*
     * 主从录入查找带值
     */
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";
      // 查找字段值为空时不走带值规则
      if (id.toString() !== "") {
        fieldJson.push({
          fieldrelateid: lookupRule.id,
          id: id,
          expression: lookupRule.expression,
          iscover: lookupRule.iscover,
        });
        let param = {
          objId: this.routerValue.objid.slice(0, 3),
          fieldJson: JSON.stringify(fieldJson),
        };
        if (this.isZhucongfield) {
          // 从对象前缀
          param.objId = this.relatedObjPrefix;
        }
        getLookupRelatedFieldValue(param).then((res) => {
          carryValue =
            res.data && res.data.length > 0 ? res.data[0].value : null;
          // 区分主从录入查找带值
          if (this.isZhucongfield) {
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === this.rowIndex - 1 &&
                editableCell.fieldId === lookupRule.id
              ) {
                editableCell.editValue = carryValue;
              }
            });
          } else {
            // 主对象查找带值
            // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
            this.formAttr.forEach((attr) => {
              if (attr.fieldId === lookupRule.id) {
                // 新建时必须修改
                if (
                  this.dialogAttr.type === "NEW" ||
                  this.dialogAttr.type === "COPY"
                ) {
                  // 查找、查找多选、主详字段特殊处理
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                  }
                  // formData !== undefined表示表单数据变化触发查找带值
                  if (formData !== undefined) {
                    // 查找、查找多选字段特殊处理
                    if (
                      (attr.fieldType === "Y" || attr.fieldType === "M") &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      formData[attr.prop] = res.data[0].lkid;
                    } else if (
                      attr.fieldType === "MR" &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      formData[attr.prop] = [res.data[0].lkid];
                    } else {
                      if (
                        (attr.fieldType === "P" ||
                          attr.fieldType === "N" ||
                          attr.fieldType === "c") &&
                        (carryValue === "" || carryValue === null)
                      ) {
                        formData[attr.prop] = undefined;
                      } else {
                        formData[attr.prop] = carryValue;
                      }
                    }
                  } else {
                    if (
                      (attr.fieldType === "Y" || attr.fieldType === "M") &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      attr.value = res.data[0].lkid;
                      this.$refs.baseForm &&
                        this.$set(
                          this.$refs.baseForm.formData,
                          attr.prop,
                          res.data[0].lkid
                        );
                    } else if (
                      attr.fieldType === "MR" &&
                      res.data[0].lkid &&
                      res.data[0].lkid !== ""
                    ) {
                      attr.value = [res.data[0].lkid];
                      this.$refs.baseForm &&
                        this.$set(this.$refs.baseForm.formData, attr.prop, [
                          res.data[0].lkid,
                        ]);
                    } else {
                      attr.value = carryValue;
                      this.$refs.baseForm &&
                        this.$set(
                          this.$refs.baseForm.formData,
                          attr.prop,
                          carryValue
                        );
                    }
                  }
                } else if (this.dialogAttr.type === "EDIT") {
                  // 编辑时
                  // formData !== undefined表示表单数据变化触发查找带值
                  if (formData !== undefined) {
                    // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
                    if (
                      formData[attr.prop] === "" ||
                      formData[attr.prop] === null ||
                      (formData[attr.prop] instanceof Array &&
                        formData[attr.prop].length === 0) ||
                      lookupRule.iscover === "true"
                    ) {
                      // 查找、查找多选字段特殊处理
                      if (
                        (attr.fieldType === "Y" ||
                          attr.fieldType === "MR" ||
                          attr.fieldType === "M") &&
                        res.data[0].value &&
                        res.data[0].lkid &&
                        res.data[0].value !== "" &&
                        res.data[0].lkid !== ""
                      ) {
                        this.optionList[attr.prop] = [
                          {
                            value: res.data[0].lkid,
                            label: res.data[0].value,
                          },
                        ];
                        if (res.data[0].lkid !== "") {
                          formData[attr.prop] =
                            attr.fieldType === "Y" || attr.fieldType === "M"
                              ? res.data[0].lkid
                              : [res.data[0].lkid];
                        }
                      } else {
                        if (
                          (attr.fieldType === "P" ||
                            attr.fieldType === "N" ||
                            attr.fieldType === "c") &&
                          (carryValue === "" || carryValue === null)
                        ) {
                          formData[attr.prop] = undefined;
                        } else {
                          formData[attr.prop] = carryValue;
                          attr.value = carryValue;
                          attr.fieldValue = carryValue;
                        }
                      }
                    }
                  } else {
                    // 如果要带值的字段值为空，带的值必须带过去 如果值不为空，iscover为‘true’时才带值
                    if (
                      attr.value === undefined ||
                      attr.value === "" ||
                      attr.value === null ||
                      lookupRule.iscover === "true"
                    ) {
                      if (
                        (attr.fieldType === "Y" ||
                          attr.fieldType === "MR" ||
                          attr.fieldType === "M") &&
                        res.data[0].value &&
                        res.data[0].lkid &&
                        res.data[0].value !== "" &&
                        res.data[0].lkid !== ""
                      ) {
                        this.optionList[attr.prop] = [
                          {
                            value: res.data[0].lkid,
                            label: res.data[0].value,
                          },
                        ];
                        if (res.data[0].lkid !== "") {
                          attr.value =
                            attr.fieldType === "Y" || attr.fieldType === "M"
                              ? res.data[0].lkid
                              : [res.data[0].lkid];
                          this.$refs.baseForm &&
                            this.$set(
                              this.$refs.baseForm.formData,
                              attr.apiname,
                              attr.fieldType === "Y" || attr.fieldType === "M"
                                ? res.data[0].lkid
                                : [res.data[0].lkid]
                            );
                        }
                      } else {
                        attr.value = carryValue;
                        this.$refs.baseForm &&
                          this.$set(
                            this.$refs.baseForm.formData,
                            attr.apiname,
                            carryValue
                          );
                      }
                    }
                  }
                }
                // 带值字段如果有字段依赖、查找、动态布局规则，执行
                let changeEvent =
                  attr.changeEvent !== undefined
                    ? attr.changeEvent.split(",")
                    : [];
                changeEvent.forEach((eventItem) => {
                  if (eventItem === "control") {
                    this.control(
                      attr.value || this.$refs.baseForm.formData[attr.prop],
                      attr.fieldId,
                      this.$refs.baseForm.formData
                    );
                  } else if (eventItem === "lookupValue") {
                    this.lookupValue(
                      attr.value || this.$refs.baseForm.formData[attr.prop],
                      attr,
                      this.$refs.baseForm.formData
                    );
                  } else if (eventItem === "handleAction") {
                    this.handleAction();
                  }
                });
              }
            });
          }
        });
      }
    },
    /*
     * 依赖关系
     */
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseForm.formData,
              true
            );
          }
        });
      });
    },
    /*
     * 依赖关系，initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
     */
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];
      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });

          // control.controlVals.forEach((vals) => {
          //   // 控制值
          //   vals.dependFields.forEach((dependField) => {
          //     this.formAttr.forEach((controlAttr, idx) => {
          //       if (controlAttr.fieldId === dependField.dependfieldid) {
          //         // 清空被控字段值并递归
          //         if (controlAttr.fieldType === 'Q') {
          //           this.$set(formData, controlAttr.apiname, [])
          //         } else {
          //           this.$set(formData, controlAttr.apiname, '')
          //         }
          //         this.control(
          //           formData[controlAttr.apiname],
          //           dependField.dependfieldid,
          //           formData
          //         )
          //       }
          //     })
          //   })
          // })
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }

        // 控制条件
        control.controlVals.forEach(() => {
          // if (vals.val === selectVal) {}
        });
      }
    },
    /*
     * 查找多选更改当前选中的值
     */
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }
      // let value = { value: optionValue };
      this.formAttr.forEach((attr) => {
        if (attr.id === fieldId) {
          this.$set(this.optionList, attr.prop, options);
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.name] = fieldVal;
          } else {
            formData[attr.name] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (
              attr.apiname ===
              lookupValue.expression.split(",")[0].toLowerCase()
            ) {
              this.getLookupValue(lookupValue, optionValue, formData);
            }
          });
        }
      });
    },
    /*
     * 收起弹出框
     */
    changeSearch() {
      this.isSearch = false;
    },
    /*
     * 查找批准人
     */
    searchPeo() {
      this.isSearch = true;
    },
    /*
     * 批准请求
     */
    submitForm() {
      this.submitType = "Approved";
      this.batchApprovalRequest();
    },
    /*
     * 取消请求
     */
    cancel() {
      if (this.$route.query.isHistory === "2") {
        this.$router.go(-1);
      } else {
        this.$router.push("/approvalCenter");
      }
    },
    /*
     * 拒绝请求
     */
    refuse() {
      this.submitType = "Reassign";
      // this.batchApprovalRequest();
    },
    /*
     * 跳转详情
     */
    goToDetail() {
      this.$router.push({
        path: `/commonObjects/detail/${this.routerValue.objid}/DETAIL`,
      });
    },
    /*
     * 返回详情
     */
    goBackDetail() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-request {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .approval-body {
    width: 100%;
    .approval-title {
      height: 55px;
      background: #f8f8f8;
      font-weight: 500;
      color: #333;
      padding-left: 60px;
      padding-top: 5px;
      .tltle {
        font-size: 16px;
      }
      .user {
        font-size: 12px;
        span {
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
    .approval-body {
      .title {
        margin-top: 14px;
        margin-left: 60px;
        font-size: 14px;
        color: #222;
      }
      .body {
        margin: 0 156px 0 106px;

        .left {
          margin-left: 360px;
        }
        .batch-button {
          cursor: pointer;
          display: inline-block;
          width: 50px;
          margin-right: 15px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          color: #006dcc;
          font-size: 12px;
          border-radius: 3px;
          border: 1px solid #dddbda;
          font-weight: 400;
        }
        .el-form {
          .select-people {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
          }
          .search-button {
            cursor: pointer;
            color: #006dcc;
            font-size: 12px;
            margin-left: 20px;
          }
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            // width: 216px;
          }
          .el-form-item__label {
            font-size: 12px;
            width: 360px;
            color: #333;
          }
          .el-input__inner {
            // width: 216px;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
            // width: 521px;
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
        }
      }
    }
  }
}
// 审批历史样式
::v-deep .approval-history {
  .history-header {
    padding: 0 60px !important;
  }

  .fix-bottom {
    padding: 20px 60px 100px 60px !important;
  }
}
</style>
